import {
    OmApplicationBeneficiaryDetailsCard,
    OmApplicationBeneficiaryList,
} from '@om/component-library-react';
import React, { useContext } from 'react';
import { PersonalAccidentContext } from '../../context/PersonalAccidentContext';

const ApplicationBeneficiaryDetailsList = ({ beneficiaryList }) => {
    const { setEditId, setRemoveId, toggleEditDrawer } = useContext(PersonalAccidentContext);
    const editHandler = (id) => {
        setEditId(id);
        toggleEditDrawer();
    };
    return (
        <OmApplicationBeneficiaryList
            count={beneficiaryList.length}
            className="sessioncamhidetext"
        >
            <div slot="beneficiary-cards">
                {beneficiaryList &&
          beneficiaryList.map((beneficiary, index) => {
              return (
                  <OmApplicationBeneficiaryDetailsCard
                      key={index}
                      number={index + 1}
                      name={beneficiary?.firstname}
                      surname={beneficiary?.OtherName}
                      onPopupModal={() => setRemoveId(beneficiary?.id)}
                      onEditBeneficiary={() => editHandler(beneficiary?.id)}
                  />
              );
          })}
            </div>
        </OmApplicationBeneficiaryList>
    );
};

export default ApplicationBeneficiaryDetailsList;
