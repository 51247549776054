module.exports.SALUTATION = [
    { fullcode: 'Mr', description: 'Mr' },
    { fullcode: 'Mrs', description: 'Mrs' },
    { fullcode: 'Miss', description: 'Miss' },
    { fullcode: 'Dr', description: 'Dr' },
    { fullcode: 'Rev', description: 'Rev' },
    { fullcode: 'Prof', description: 'Prof' },
    { fullcode: 'Eng', description: 'Eng' },
    { fullcode: 'Pastor', description: 'Pastor' },
    { fullcode: 'Hon', description: 'Hon' },
    { fullcode: 'Alhaji', description: 'Alhaji' },
    { fullcode: 'Ms', description: 'Ms' },
    { fullcode: 'Madam', description: 'Madam' },
];
