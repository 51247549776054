module.exports.RELATIONSHIPS = [
    { fullcode: 'Spouse', description: 'Spouse' },
    { fullcode: 'Son', description: 'Son' },
    { fullcode: 'Daughter', description: 'Daughter' },
    { fullcode: 'Father', description: 'Father' },
    { fullcode: 'Mother', description: 'Mother' },
    { fullcode: 'Sister', description: 'Sister' },
    { fullcode: 'Brother', description: 'Brother' },
    { fullcode: 'Mother in-law', description: 'Mother in-law' },
    { fullcode: 'Father in-law', description: 'Father in-law' },
    { fullcode: 'Aunt', description: 'Aunt' },
    { fullcode: 'Brother in-law', description: 'Brother in-law' },
    { fullcode: 'Cousin', description: 'Cousin' },
    { fullcode: 'Daughter in-law', description: 'Daughter in-law' },
    { fullcode: 'Grand Daughter', description: 'Grand Daughter' },
    { fullcode: 'Grand Mother', description: 'Grand Mother' },
    // { fullcode: "Grandparent", description: "Grandparent" },
    { fullcode: 'Nephew', description: 'Nephew' },
    { fullcode: 'Niece', description: 'Niece' },
    { fullcode: 'Sister in-law', description: 'Sister in-law' },
    { fullcode: 'Son in-law', description: 'Son in-law' },
    { fullcode: 'Uncle', description: 'Uncle' },
    // { fullcode: 'Wife', description: 'Wife' },
    { fullcode: 'Neighbour', description: 'Neighbour' },
];
