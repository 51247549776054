import React, { useContext } from 'react';
import './ReviewPage.scss';
import { PersonalAccidentContext } from '../../context/PersonalAccidentContext';
import { PAGE_INDICES } from '../../constants/routing';
import { OmApplicationReviewSection,OmLabeledValueSummary,OmCheckBox,OmFormError, OmModalLoader,OmApplicationSummaryDetailsCard } from '@om/component-library-react';
import { OmdsCell,OmdsGrid } from '@mom-omds/react-design-system-components/dist/generated/components';
import { calculateSumAssuredPremium, formatOccupationClass } from '../../../../utils/personal-accident-utils/util';
import SelectTelco from '../modal/SelectTelco';

const ReviewPage = () => {
    const {
        navigateTo,
        accidentData,
        beneficiaryListDataAccident,
        hasAcceptedTermsAndCondition,
        acceptTermsAndCondition,
        togglePaymentModal,
        paymentModal
    } = useContext(PersonalAccidentContext);

    const joinName = (nameArray) => { return nameArray.join(' ') };

    const toggle = () => { acceptTermsAndCondition();  };

    return (
        <div id='pa_confirmation'>
            <OmdsGrid isFixed>
                <OmdsCell span="row" span-md="7">
                    <div>
                        <h4 className="page-heading sessioncamhidetext">Great choice, {`${accidentData?.schemeDetails?.first_name} ${accidentData?.schemeDetails?.last_name}`}. Here’s an overview of your policy cover</h4>
                        
                        <div>
                            <OmApplicationReviewSection titleHeader="Product Summary">
                                <span slot="edit-link" onClick={() => navigateTo(PAGE_INDICES.POLICY_DETAILS)}>
                                    <h6 className="small"><strong>EDIT</strong></h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Sum Assured"
                                                value={`GH₵${accidentData?.schemeDetails?.sum_assured}`}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Commencement Date"
                                                value={accidentData?.schemeDetails?.commencement_date}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>
                            
                            <OmApplicationReviewSection titleHeader="Personal Details">
                                <span
                                    slot="edit-link"
                                    onClick={() => navigateTo(PAGE_INDICES.PERSONAL_DETAILS)}
                                >
                                    <h6 className="small">
                                        <strong>EDIT</strong>
                                    </h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Full name"
                                                value={joinName([
                                                    accidentData?.schemeDetails?.first_name,
                                                    accidentData?.schemeDetails?.last_name,
                                                ])}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Date of birth"
                                                value={accidentData?.schemeDetails?.birth_date}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Gender"
                                                value={accidentData?.schemeDetails?.gender}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Country of birth"
                                                value={accidentData?.schemeDetails?.country_of_birth}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="ID Type"
                                                value={accidentData?.schemeDetails?.idType}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="ID Number"
                                                value={accidentData?.schemeDetails?.id_number}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Marital Status"
                                                value={accidentData?.schemeDetails?.marital_status}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Nationality"
                                                value={accidentData?.schemeDetails?.nationality}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            <OmApplicationReviewSection titleHeader="Contact Details">
                                <span slot="edit-link" onClick={() => navigateTo(PAGE_INDICES.PERSONAL_DETAILS)}>
                                    <h6 className="small"><strong>EDIT</strong></h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="6">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Mobile number"
                                                value={accidentData?.schemeDetails?.mobile_number}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Email Address"
                                                value={accidentData?.schemeDetails?.email_address}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            <OmApplicationReviewSection titleHeader="Address Details">
                                <span slot="edit-link" onClick={() => navigateTo(PAGE_INDICES.PERSONAL_DETAILS)}>
                                    <h6 className="small"><strong>EDIT</strong></h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="5">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Residential /Postal address"
                                                value={`${accidentData?.schemeDetails?.street_address}, ${accidentData?.schemeDetails?.city}`}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Region"
                                                value={accidentData?.schemeDetails?.region}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            <OmApplicationReviewSection titleHeader="Occupation Details">
                                <span slot="edit-link" onClick={() => navigateTo(PAGE_INDICES.PERSONAL_DETAILS)}>
                                    <h6 className="small"><strong>EDIT</strong></h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="5">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Occupation"
                                                value={accidentData?.schemeDetails?.occupation}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Occupation Class"
                                                value={formatOccupationClass(accidentData?.schemeDetails?.occupation_class)}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="5">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Are You a Politically Exposed Person?"
                                                value={accidentData?.schemeDetails?.politically_exposed}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            <OmApplicationReviewSection titleHeader="Health Information">
                                <span slot="edit-link" onClick={() => navigateTo(PAGE_INDICES.HEALTH_INFORMATION)}>
                                    <h6 className="small"><strong>EDIT</strong></h6>
                                </span>
                                <div slot="section-body">
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="5">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Do you smoke?"
                                                value={accidentData?.schemeDetails?.do_you_smoke}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Are you currently free from any significant health issues and generally in good health?"
                                                value={accidentData?.schemeDetails?.good_health}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="5">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Do you have any existing personal accident insurance?"
                                                value={accidentData?.schemeDetails?.existing_accident_insurance}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Do you currently hold a life insurance policy with this company?"
                                                value={accidentData?.schemeDetails?.existing_policy}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                    <OmdsGrid>
                                        <OmdsCell span="row" span-md="5">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Are you involved in activities like polo, mountaineering, yachting, racing, steeple chasing, hunting, big game shooting, or motorcycling?"
                                                value={accidentData?.schemeDetails?.involved_in_activities}
                                            />
                                        </OmdsCell>
                                        <OmdsCell span="row" span-md="7..">
                                            <OmLabeledValueSummary
                                                isSensitive={true}
                                                label="Please share any illnesses or accidents for which you've received medical attention in the past 5 years"
                                                value={accidentData?.schemeDetails?.any_illness}
                                            />
                                        </OmdsCell>
                                    </OmdsGrid>
                                </div>
                            </OmApplicationReviewSection>

                            {beneficiaryListDataAccident && beneficiaryListDataAccident.length > 0 && (
                                <OmApplicationReviewSection titleHeader="Beneficiary Details">
                                    <span
                                        slot="edit-link"
                                        onClick={() => navigateTo(PAGE_INDICES.BENEFICIARIES)}
                                    >
                                        <h6 className="small">
                                            <strong>EDIT</strong>
                                        </h6>
                                    </span>
                                    {beneficiaryListDataAccident.length > 1 ? (
                                        <div slot="section-body">
                                            {beneficiaryListDataAccident.map((beneficiary, index) => {
                                                return (
                                                    <div key={index}>
                                                        <OmdsGrid>
                                                            <OmdsCell span="row" span-md="6">
                                                                {' '}
                                                                <OmLabeledValueSummary
                                                                    isSensitive={true}
                                                                    className="beneficiary-name"
                                                                    label="Full name"
                                                                    value={joinName([
                                                                        beneficiary?.firstname,
                                                                        beneficiary?.OtherName,
                                                                    ])}
                                                                />
                                                            </OmdsCell>
                                                            <OmdsCell span="row" span-md="7..">
                                                                {' '}
                                                                <OmLabeledValueSummary
                                                                    className="beneficiary-split"
                                                                    label="Split"
                                                                    value={beneficiary?.perc_alloc + '%'}
                                                                />
                                                            </OmdsCell>
                                                        </OmdsGrid>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div slot="section-body">
                                            <OmdsGrid>
                                                <OmdsCell span="row" span-md="6">
                                                    <OmLabeledValueSummary
                                                        isSensitive={true}
                                                        className="beneficiary-name"
                                                        label="Full name"
                                                        value={joinName([
                                                            beneficiaryListDataAccident[0]?.firstname,
                                                            beneficiaryListDataAccident[0]?.OtherName,
                                                        ])}
                                                    />
                                                </OmdsCell>
                                            </OmdsGrid>
                                        </div>
                                    )}
                                </OmApplicationReviewSection>
                            )}
                        </div>
                    </div>
                </OmdsCell>
                <OmdsCell span="row" span-md="8..">
                    <OmApplicationSummaryDetailsCard>
                        <div slot="content">
                            <p><strong>Total Premium</strong></p>
                            <h1 style={{ marginHeight: '10px' }}>GH₵ {calculateSumAssuredPremium(accidentData?.schemeDetails?.sum_assured, formatOccupationClass(accidentData?.schemeDetails?.occupation_class))}</h1>
                            
                            <div style={{ marginTop: '3rem' }} className="check-box-container">
                                <OmCheckBox
                                    id="terms-and-conditions-checkbox"
                                    checked={hasAcceptedTermsAndCondition}
                                    onOnCheckBoxChanged={() => toggle()}
                                >
                                    <p slot="text-slot">
                  I accept the&nbsp;
                                        <a target="_blank" rel="noopener noreferrer" style={{color: '#50b848'}}
                                            href="https://eu-assets.contentstack.com/v3/assets/blta881ace037f02dd2/blt749c91d66f7a1776/661f7ff274821b62aa26cb26/PERSONAL_ACCIDENT_T_s_and_C_s_(5).pdf">
                    declarations.
                                        </a>
                                    </p>
                                </OmCheckBox>
                                <br />
                                <OmFormError message={hasAcceptedTermsAndCondition === true ? undefined : 'Please select checkbox before you can continue'} />
                            </div>
                        </div>
                    </OmApplicationSummaryDetailsCard>
                </OmdsCell>
            </OmdsGrid>

            <OmModalLoader
                slot="loading-modal"
                loaderText="Please wait while we submit your data..."
                verticalAlignMiddle={true}
                open={accidentData?.schemeDetails?.loading}
            />

            <SelectTelco open={paymentModal} onCloseHandler={() => togglePaymentModal()} />
        </div>
    );
};

export default ReviewPage;
