import React, { useContext }  from 'react';
import './healthPage.scss'
import {OmRadioButtonGroup, OmFormInputFieldWrapper} from '@om/component-library-react';
import { PersonalAccidentContext } from '../../context/PersonalAccidentContext';
import { DEFAULT_VALIDATION_DELAY } from '../../../../configs/personal-accident-configs/config';
import { isInputValid } from '../../../../utils/personal-accident-utils/util';

const Health = () => {

    const { accidentData, updateAccidentData } = useContext(PersonalAccidentContext);

    return (
        <div className="mvest-scheme-details-page" id='pa_health'>
            <div className="mvest-scheme-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">Great, Let’s get your health information</h5>
                <p>Please note that you need to fill this to the greatest deal of accuracy.</p>

                <div>
                    <OmRadioButtonGroup
                        id="do_you_smoke"
                        name="do_you_smoke"
                        vertical={true}
                        groupName="do_you_smoke"
                        value={accidentData?.schemeDetails?.do_you_smoke}
                        onOnSelection={(e) => updateAccidentData({ do_you_smoke: e.detail }, 'schemeDetails')}
                        label="Do you smoke?"
                        labelAlwaysShow={true}>
                        <ul slot="list-items">
                            <li value="Yes">Yes</li>
                            <li value="No">No</li>
                        </ul>
                    </OmRadioButtonGroup>
                </div>
                <div>
                    <OmRadioButtonGroup
                        id="good_health"
                        name="good_health"
                        vertical={true}
                        groupName="good_health"
                        value={accidentData?.schemeDetails?.good_health}
                        onOnSelection={(e) => updateAccidentData({ good_health: e.detail }, 'schemeDetails')}
                        label="Are you currently free from any significant health issues and generally in good health?"
                        labelAlwaysShow={true}>
                        <ul slot="list-items">
                            <li value="Yes">Yes</li>
                            <li value="No">No</li>
                        </ul>
                    </OmRadioButtonGroup>
                </div>
                <div>
                    <OmRadioButtonGroup
                        id="existing_accident_insurance"
                        name="existing_accident_insurance"
                        vertical={true}
                        groupName="existing_accident_insurance"
                        value={accidentData?.schemeDetails?.existing_accident_insurance}
                        onOnSelection={(e) => updateAccidentData({ existing_accident_insurance: e.detail }, 'schemeDetails')}
                        label="Do you have any existing personal accident insurance?"
                        labelAlwaysShow={true}>
                        <ul slot="list-items">
                            <li value="Yes">Yes</li>
                            <li value="No">No</li>
                        </ul>
                    </OmRadioButtonGroup>
                </div>
                <div>
                    <OmRadioButtonGroup
                        id="existing_policy"
                        name="existing_policy"
                        vertical={true}
                        groupName="existing_policy"
                        value={accidentData?.schemeDetails?.existing_policy}
                        onOnSelection={(e) => updateAccidentData({ existing_policy: e.detail }, 'schemeDetails')}
                        label="Do you currently hold a life insurance policy with this company?"
                        labelAlwaysShow={true}>
                        <ul slot="list-items">
                            <li value="Yes">Yes</li>
                            <li value="No">No</li>
                        </ul>
                    </OmRadioButtonGroup>
                </div>
                <div>
                    <OmRadioButtonGroup
                        id="involved_in_activities"
                        name="involved_in_activities"
                        vertical={true}
                        groupName="involved_in_activities"
                        value={accidentData?.schemeDetails?.involved_in_activities}
                        onOnSelection={(e) => updateAccidentData({ involved_in_activities: e.detail }, 'schemeDetails')}
                        label="Are you involved in activities like polo, mountaineering, yachting, racing, steeple chasing, hunting, big game shooting, or motorcycling?"
                        labelAlwaysShow={true}>
                        <ul slot="list-items">
                            <li value="Yes">Yes</li>
                            <li value="No">No</li>
                        </ul>
                    </OmRadioButtonGroup>
                </div>
                <div>
                    <OmFormInputFieldWrapper
                        label="Please share any illnesses or accidents for which you've received medical attention in the past 5 years"
                        id="any_illness"
                        name="any_illness"
                        labelAlwaysShow={true}
                        isSensitive
                        required
                        formId="schemeDetailsForm"
                        size="full"
                        align="left"
                        type="text"
                        placeholder="e.g. Knee injury"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={accidentData?.schemeDetails?.any_illness}
                        state={isInputValid(accidentData?.schemeDetails?.any_illness)}
                        errorMessage={isInputValid(accidentData?.schemeDetails?.any_illness) === 'error' ? 'Please share if any illness' : undefined }
                        onPersist={(e) => updateAccidentData({ any_illness: e.detail }, 'schemeDetails')}
                    />
                </div>
            </div>
        </div>
    )
}
export default Health;