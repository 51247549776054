import React from 'react';
import info1 from '../../assets/images/info1.png';
import info2 from '../../assets/images/info2.png';
import info3 from '../../assets/images/info3.png';

const StartPage = () => {

    return (
        <div className="mvest-scheme-details-page" id='pa_start_page'>
            <div className="mvest-scheme-details-page-wrapper">
                <h3 className="heading medium" id="heading-anchor">Let’s make sure you have everything you need.</h3>
                <div>
                    <div slot="content" style={{display: 'flex'}}>
                        <div className="">
                            <img loading="lazy" src={info1} alt='info-img' style={{ marginTop: '35px',marginRight:'20px' }}/>
                        </div>
                        <div className="">
                            <h5 style={{marginBottom:'0px'}}>Basic Identification Documents</h5>
                            <p>We will need your Ghana Card or Voters Card, cellphone number, residential address and verification purposes.</p>
                        </div>
                    </div>
                    <div slot="content" style={{display: 'flex'}}>
                        <div className="">
                            <img loading="lazy" src={info2} alt='info-img' style={{ marginTop: '35px',marginRight:'20px' }}/>
                        </div>
                        <div className="">
                            <h5 style={{marginBottom:'0px'}}>Your health information</h5>
                            <p>We need your health information to the greatest deal of accuracy</p>
                        </div>
                    </div>
                    <div slot="content" style={{display: 'flex'}}>
                        <div className="">
                            <img loading="lazy" src={info3} alt='info-img' style={{ marginTop: '35px',marginRight:'20px' }}/>
                        </div>
                        <div className="">
                            <h5 style={{marginBottom:'0px'}}>For beneficiaries - those who will receive the claim</h5>
                            <p>We need the first name, surname and contact details, to ensure any future claims can be paid out quickly and to the right people.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default StartPage;