import React, { createContext, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

const PersonalAccidentContext = createContext({
    // formData getters
    accidentData: {
        schemeDetails: {
            title: undefined,
            first_name: undefined,
            last_name: undefined,
            birth_date: undefined,
            country_of_birth: undefined,
            marital_status: undefined,
            gender: undefined,
            nationality: 'Ghana',
            idType: undefined,
            id_number: undefined,
            email_address: undefined,
            mobile_number: undefined,
            momo_number: undefined,
            country_of_residence: 'Ghana',
            region: undefined,
            street_address: undefined,
            city: undefined,
            postal_code: undefined,
            occupation: undefined,
            occupation_class: undefined,
            politically_exposed: undefined,
            do_you_smoke: undefined,
            good_health: undefined,
            existing_accident_insurance: undefined,
            existing_policy: undefined,
            involved_in_activities: undefined,
            any_illness: undefined,
            sum_assured: undefined,
            commencement_date: undefined,
            agentCode: undefined,
            loading: false,
            paymentModal: false,
        }
    },
    beneficiaryListDataAccident: [],
    idToRemove: 0,
    idToEdit: 0,
    beneficiaryId: 0,
    hasAcceptedTermsAndCondition: false,
    // formData setters
    updateAccidentData: () => {},
    addBeneficiaryToListData: () => {},
    updatebeneficiaryListDataAccident: () => {},
    deleteBeneficiary: () => {},
    getBeneficiaryToEdit: () => {},
    setBeneficiaryId: () => {},
    setRemoveId: () => {},
    setEditId: () => {},
    updateObjectInArray: () => {},
    handleFileInput: () => {},
    removeFile:() => {},

    // app state
    accidentActivePageIndex: 0,
    navigatePrevious: () => {},
    navigateContinue: () => {},
    navigateTo: () => {},
    togglePaymentModal: () => {},

    // drawer
    isDrawerOpen: false,
    isEditDrawerOpen: false,
    isModalOpen: false,
    isEvenSplit: false,
    paymentModal: false,
    splitEvenly: () => {},
    toggleDrawer: () => {},
    toggleEditDrawer: () => {},
    toggleMaxBeneficiariesModal: () => {},
    acceptTermsAndCondition: () => {},
    toggleLoading: () => {},
});

const PersonalAccidentProvider = ({ children }) => {
 
    const [accidentActivePageIndex, setActivePageIndex] = useLocalStorage('accidentActivePageIndex', 0);
    const [accidentData, setAccidentData] = useLocalStorage('accidentData', {
        schemeDetails: {
            title: undefined,
            first_name: undefined,
            last_name: undefined,
            birth_date: undefined,
            country_of_birth: undefined,
            marital_status: undefined,
            gender: undefined,
            nationality: 'Ghana',
            id_type: undefined,
            id_number: undefined,
            email_address: undefined,
            mobile_number: undefined,
            momo_number: undefined,
            country_of_residence: 'Ghana',
            region: undefined,
            street_address: undefined,
            city: undefined,
            postal_code: undefined,
            occupation: undefined,
            occupation_class: undefined,
            politically_exposed: undefined,
            do_you_smoke: undefined,
            good_health: undefined,
            existing_accident_insurance: undefined,
            existing_policy: undefined,
            involved_in_activities: undefined,
            any_illness: undefined,
            sum_assured: undefined,
            commencement_date: undefined,
            agentCode: undefined,
            loading: false,
            paymentModal: false,
        }
    });
    const [beneficiaryListDataAccident, setbeneficiaryListDataAccident] = useLocalStorage('beneficiaryListDataAccident', []);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEvenSplit, setIsEvenSplit] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [beneficiaryId, setId] = useState(0);
    const [idToRemove, setIdToRemove] = useState(0);
    const [idToEdit, setIdToEdit] = useState(0);
    const [hasAcceptedTermsAndCondition, setHasAcceptedTermsAndCondition] = useLocalStorage('hasAcceptedTermsAndCondition', false);
    const navigatePrevious = () => { setActivePageIndex((prevState) => prevState - 1); };
    const navigateContinue = () => { setActivePageIndex((prevState) => prevState + 1); };
    const navigateTo = (page) => { setActivePageIndex(page); };
    const toggleDrawer = () => { setIsDrawerOpen((prevState) => !prevState); };
    const splitEvenly = () => { setIsEvenSplit((prevState) => !prevState); };
    const toggleEditDrawer = () => { setIsEditDrawerOpen((prevState) => !prevState); };
    const toggleMaxBeneficiariesModal = () => { setIsModalOpen((prevState) => !prevState); };

    const acceptTermsAndCondition = () => { setHasAcceptedTermsAndCondition((prevState) => !prevState); };
    const updateAccidentData = (obj, page) => {
        setAccidentData((prevData) => ({
            ...prevData,
            [page]: { ...prevData[page], ...obj },
        }));
    };

    const togglePaymentModal = () => { setPaymentModal((prevState) => !prevState); };

    const addBeneficiaryToListData = (obj) => { setbeneficiaryListDataAccident((prevArray) => [...prevArray, obj]); };

    const updatebeneficiaryListDataAccident = (arr) => { setbeneficiaryListDataAccident(arr); };

    const updateObjectInArray = (arr, { id, ...updatedObj }) => {
        const index = arr.findIndex((obj) => obj.id === id);
        if (index === -1) {
            setbeneficiaryListDataAccident(arr);
        }
        const newArr = [...arr];
        newArr[index] = {
            ...newArr[index],
            ...updatedObj,
        };
        setbeneficiaryListDataAccident(newArr);
    };

    const deleteBeneficiary = (id) => {
        setbeneficiaryListDataAccident((prevArray) =>
            prevArray.filter((obj) => obj.id !== id)
        );
    };
    const getBeneficiaryToEdit = () => {
        return beneficiaryListDataAccident[
            beneficiaryListDataAccident.findIndex((obj) => obj.id === idToEdit)
        ];
    };
    const setBeneficiaryId = () => { setId((prevId) => prevId + 1); };

    const setRemoveId = (idToRemove) => { setIdToRemove(idToRemove); };

    const setEditId = (idToEdit) => { setIdToEdit(idToEdit); };

    return (
        <PersonalAccidentContext.Provider
            value={{
                accidentData,
                accidentActivePageIndex,
                isDrawerOpen,
                beneficiaryListDataAccident,
                beneficiaryId,
                isModalOpen,
                isEditDrawerOpen,
                idToRemove,
                idToEdit,
                hasAcceptedTermsAndCondition,
                paymentModal,
                togglePaymentModal,
                updateAccidentData,
                navigateContinue,
                navigatePrevious,
                navigateTo,
                toggleDrawer,
                addBeneficiaryToListData,
                updatebeneficiaryListDataAccident,
                deleteBeneficiary,
                setRemoveId,
                setEditId,
                toggleMaxBeneficiariesModal,
                setBeneficiaryId,
                toggleEditDrawer,
                getBeneficiaryToEdit,
                updateObjectInArray,
                splitEvenly,
                acceptTermsAndCondition,
            }}
        >
            {children}
        </PersonalAccidentContext.Provider>
    );
};

export { PersonalAccidentContext, PersonalAccidentProvider };
