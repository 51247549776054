import React, { useContext, useEffect, useState } from 'react';
import { DEFAULT_VALIDATION_DELAY } from '../../../../../../ghana/configs/personal-accident-configs/config';
import {OmFormDropdownFieldWrapper, OmFormInputFieldWrapper, OmRadioButtonGroup, OmModalLoader} from '@om/component-library-react';
import { PersonalAccidentContext } from '../../../context/PersonalAccidentContext';
import { getAgeValue, isInputValid, isValidEmail, isValidName, stripSystemEmojis, stripZeroPhoneNumber } from '../../../../../../ghana/utils/personal-accident-utils/util';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';
import { PAGE_INDICES } from '../../../constants/routing';
import { getAllOccupationFromSlams } from '../../../server/api';
import useIdTypeSelection from '../../../hooks/useIdTypeSelection';
const referenceNationalities = require('../../../constants/refeferenceData/countries');
const referenceGender = require('../../../constants/refeferenceData/genders');
const referenceMarital = require('../../../constants/refeferenceData/marital_status');
const referenceClasses = require('../../../constants/refeferenceData/occupation_class');
const referenceRegions = require('../../../constants/refeferenceData/ghanaRegions');
const referenceSalutation = require('../../../constants/refeferenceData/salutations')
const referenceIdTypes = require('../../../constants/refeferenceData/idTypes')
const referenceOccupations = require('../../../constants/refeferenceData/occupation')
const agents = require('../../../constants/refeferenceData/agents');


const SchemeDetailsSection = () => {

    const { accidentData, updateAccidentData } = useContext(PersonalAccidentContext);


    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;

        let formattedValue = '';

        if (input === 'id_number') {
            const strippedValue = value.replace(/-/g, '');
            if (accidentData?.schemeDetails?.idType === 'Ghana Card Number') {
                formattedValue = strippedValue.replace(/^(\w{3})(\w{9})(\w)$/, '$1-$2-$3');
            }else if (accidentData?.schemeDetails?.idType === 'Voter’s card') {
                formattedValue = value;
            } else {
                formattedValue = value;
            }
        }else if (input === 'mobile_number') {
            formattedValue = value.startsWith('+233') ? value : '+233' + stripZeroPhoneNumber(value);
            if(formattedValue.length < 12){
                updateAccidentData({ [input]: null }, 'schemeDetails');
                return;
            }
        }else if(input === 'first_name' || input === 'last_name'){
            if(!isValidName(value) || value.trim().length < 2){
                updateAccidentData({ [input]: null }, 'schemeDetails');
                return;
            }
            formattedValue = stripSystemEmojis(value)
        }else if(input === 'birth_date'){
            // console.log(value,getAgeValue(value))
            if(getAgeValue(value) < 18){
                // console.log('Less than 18 years', getAgeValue(value))
                updateAccidentData({ birth_date: null }, 'schemeDetails');
                return;
            }else if(getAgeValue(value) > 55){
                // console.log('Greater than 55 years', getAgeValue(value))
                updateAccidentData({ birth_date: null }, 'schemeDetails');
                return;
            }else{
                // console.log('Correct value', getAgeValue(value))
                updateAccidentData({ birth_date: value }, 'schemeDetails');
                return;
            }
        }else if(input === 'street_address'){
            if(value.trim().length < 2){
                updateAccidentData({ [input]: null }, 'schemeDetails');
                return;
            }
            formattedValue = stripSystemEmojis(value)
        }else{
            formattedValue = value;
        }
        updateAccidentData({ [input]: formattedValue }, 'schemeDetails');
    }

    const handleResidence = (e) => {
        if(e.detail.value !== 'Ghana'){
            updateAccidentData({ country_of_residence: null }, 'schemeDetails');
            alert('Only people residing in Ghana are allowed!')
        }else{
            updateAccidentData({ country_of_residence: e.detail.value }, 'schemeDetails');
        }
    }

    return (
        <>
            <h6 className="medium no-bottom-margin">Personal Details</h6>

            <OmFormDropdownFieldWrapper
                id="title"
                name="title"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Title"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.title}
                state={isInputValid(accidentData?.schemeDetails?.title)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.title) === 'error' ? 'Select a title that describes you' : undefined }
                options={
                    referenceSalutation &&
            JSON.stringify(
                referenceSalutation.SALUTATION.map((option) => ({
                    value: option.fullcode,
                    label: option.description,
                }))
            )
                }
                onOnSelect={(e) => updateAccidentData({ title: e.detail.value }, 'schemeDetails') }
            />

            <OmFormInputFieldWrapper
                label="First name"
                id="first_name"
                name="first_name"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. John"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={accidentData?.schemeDetails?.first_name}
                state={isInputValid(accidentData?.schemeDetails?.first_name)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.first_name) === 'error' ? 'Please enter your first name' : undefined }
                onPersist={(e) => handleChange(e)}
            />
            <OmFormInputFieldWrapper
                label="Surname"
                id="last_name"
                name="last_name"
                labelAlwaysShow={true}
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. Doe"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={accidentData?.schemeDetails?.last_name}
                state={isInputValid(accidentData?.schemeDetails?.last_name)}
                errorMessage={ isInputValid(accidentData?.schemeDetails?.last_name) === 'error' ? 'Please enter your last name' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormDropdownFieldWrapper
                id="country_of_birth"
                name="country_of_birth"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Country of birth"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.country_of_birth}
                state={isInputValid(accidentData?.schemeDetails?.country_of_birth)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.country_of_birth) === 'error' ? 'Please select your country of birth' : undefined }
                options={
                    referenceNationalities &&
            JSON.stringify(
                referenceNationalities.COUNTRIES.map((option) => ({
                    value: option.fullcode,
                    label: option.description,
                }))
            )
                }
                onOnSelect={(e) => updateAccidentData({ country_of_birth: e.detail.value }, 'schemeDetails') }
            />

            <OmFormDropdownFieldWrapper
                id="marital_status"
                name="marital_status"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Marital Status"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.marital_status}
                state={isInputValid(accidentData?.schemeDetails?.marital_status)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.marital_status) === 'error' ? 'Please select your marital status' : undefined }
                options={
                    referenceMarital &&
                JSON.stringify(
                    referenceMarital.MARITAL_STATUS.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) => updateAccidentData({ marital_status: e.detail.value }, 'schemeDetails') }
            />

            <OmFormDropdownFieldWrapper
                id="gender"
                name="gender"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Gender"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.gender}
                state={isInputValid(accidentData?.schemeDetails?.gender)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.gender) === 'error' ? 'Please select your gender' : undefined }
                options={
                    referenceGender &&
                JSON.stringify(
                    referenceGender.GENDERS.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) => updateAccidentData({ gender: e.detail.value }, 'schemeDetails') }
            />

            <OmFormDropdownFieldWrapper
                id="nationality"
                name="nationality"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Nationality"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.nationality}
                state={isInputValid(accidentData?.schemeDetails?.nationality)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.nationality) === 'error' ? 'Please select your nationality' : undefined }
                options={
                    referenceNationalities &&
            JSON.stringify(
                referenceNationalities.COUNTRIES.map((option) => ({
                    value: option.fullcode,
                    label: option.description,
                }))
            )
                }
                onOnSelect={(e) => updateAccidentData({ nationality: e.detail.value }, 'schemeDetails') }
            />

            <OmdsDatePickerField
                id='birth_date'
                label="Date of birth"
                name="birth_date"
                value={accidentData?.schemeDetails?.birth_date}
                hasError={isInputValid(accidentData?.schemeDetails?.birth_date) === 'error'}
                onPersist={(e) => handleChange(e)}
                placeholder="e.g. 1980-05-05">
                {isInputValid(accidentData?.schemeDetails?.birth_date) === 'error' ? (<span slot="hint-text">{'You must be at least 18 Years and not more than 55 Years'}</span>) : undefined}
            </OmdsDatePickerField>


            <OmFormDropdownFieldWrapper
                id="idType"
                name="idType"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="ID Type"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.idType}
                state={isInputValid(accidentData?.schemeDetails?.idType)}
                errorMessage={ isInputValid(accidentData?.schemeDetails?.idType) === 'error' ? 'Please select your ID Type' : undefined }
                options={ referenceIdTypes &&
                JSON.stringify(
                    referenceIdTypes.ID_TYPES.map((option) => ({
                        value: option.fullcode,
                        label: option.description,
                    }))
                )
                }
                onOnSelect={(e) =>
                    updateAccidentData({ idType: e.detail.value }, 'schemeDetails')
                }
            />

            {/* <OmRadioButtonGroup
                id="idType"
                name="idType"
                vertical={true}
                groupName="id_types"
                value={accidentData?.schemeDetails?.idType}
                onOnSelection={(e) => handleChange(e)}
                label="Select identity type:"
                labelAlwaysShow={true}>
                <ul slot="list-items">
                    <li value="Ghana Card Number">Ghana Card</li>
                    <li value="Voter’s card">Voter’s card</li>
                </ul>
            </OmRadioButtonGroup> */}

            <OmFormInputFieldWrapper
                label={useIdTypeSelection(referenceIdTypes, accidentData)?.label}
                id="id_number"
                name="id_number"
                isSensitive
                labelAlwaysShow={true}
                maxLength={useIdTypeSelection(referenceIdTypes, accidentData)?.maxLength}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder={useIdTypeSelection(referenceIdTypes, accidentData)?.placeholder}
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={accidentData?.schemeDetails?.id_number}
                state={isInputValid(accidentData?.schemeDetails?.id_number)}
                errorMessage={useIdTypeSelection(referenceIdTypes, accidentData)?.validator() ? useIdTypeSelection(referenceIdTypes, accidentData)?.errorMessage : undefined}
                onPersist={(e) => handleChange(e)}
            />

            <h6 className="medium no-bottom-margin" id="heading-anchor">Contact Details</h6>
            <OmFormInputFieldWrapper
                label="Email Address"
                id="email_address"
                name="email_address"
                labelAlwaysShow={true}
                type="email"
                isSensitive
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                keyboardMode="email"
                placeholder="e.g. johndoe@gmail.com"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={accidentData?.schemeDetails?.email_address}
                state={isInputValid(accidentData?.schemeDetails?.email_address)}
                errorMessage={ (isInputValid(accidentData?.schemeDetails?.email_address) === 'error' || !isValidEmail(accidentData?.schemeDetails?.email_address) ) ? 'Please enter a valid email address' :  undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Mobile number"
                id="mobile_number"
                name="mobile_number"
                maxLength={13}
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. +233 54 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={accidentData?.schemeDetails?.mobile_number}
                state={isInputValid(accidentData?.schemeDetails?.mobile_number)}
                errorMessage={ isInputValid(accidentData?.schemeDetails?.mobile_number) === 'error' ? 'Please enter your mobile number.' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <OmFormInputFieldWrapper
                label="Mobile Money Number (MTN or Telecel)"
                id="momo_number"
                name="momo_number"
                maxLength={10}
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                placeholder="e.g. 054 891 2451"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                value={accidentData?.schemeDetails?.momo_number}
                state={isInputValid(accidentData?.schemeDetails?.momo_number)}
                errorMessage={ isInputValid(accidentData?.schemeDetails?.momo_number) === 'error' ? 'Please enter your mobile number.' : undefined }
                onPersist={(e) => handleChange(e)}
            />

            <h6 className="medium no-bottom-margin" id="heading-anchor">Occupation Details</h6>
            <OmFormDropdownFieldWrapper
                id="occupation_class"
                name="occupation_class"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Eg. Class 2"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.occupation_class}
                state={isInputValid(accidentData?.schemeDetails?.occupation_class)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.occupation_class) === 'error' ? 'Please select your occupation class' : undefined }
                options={
                    referenceClasses &&
            JSON.stringify(
                referenceClasses.CLASSES.map((option) => ({
                    value: option.fullcode,
                    label: option.description,
                }))
            )
                }
                onOnSelect={(e) => updateAccidentData({ occupation_class: e.detail.value }, 'schemeDetails') }
            />

            <OmFormDropdownFieldWrapper
                id="occupation"
                name="occupation"
                useNativeMobileDropdown
                className="inline-field"
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Type or Select occupation"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.occupation}
                state={isInputValid(accidentData?.schemeDetails?.occupation)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.occupation) === 'error' ? 'Please select your occupation' : undefined }
                options={
                    referenceOccupations &&
                JSON.stringify(
                    referenceOccupations.OCCUPATIONS.map((option) => ({
                        value: option.occupation_name,
                        label: option.occupation_name,
                    }))
                )
                }
                onOnSelect={(e) => updateAccidentData({ occupation: e.detail.value }, 'schemeDetails') }
            />

            <OmRadioButtonGroup
                id="politically_exposed"
                name="politically_exposed"
                vertical={true}
                groupName="politically_exposed"
                value={accidentData?.schemeDetails?.politically_exposed}
                onOnSelection={(e) => handleChange(e)}
                label="Are you policitically exposed person?"
                labelAlwaysShow={true}>
                <ul slot="list-items">
                    <li value="Yes">Yes</li>
                    <li value="No">No</li>
                </ul>
            </OmRadioButtonGroup>

            <h6 className="medium no-bottom-margin" id="heading-anchor">Address Details</h6>
            <OmFormDropdownFieldWrapper
                id="country_of_residence"
                name="country_of_residence"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Country of Residence"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.country_of_residence}
                state={isInputValid(accidentData?.schemeDetails?.country_of_residence)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.country_of_residence) === 'error' ? 'Please select your country of residence' : undefined }
                options={
                    referenceNationalities &&
            JSON.stringify(
                referenceNationalities.COUNTRIES.map((option) => ({
                    value: option.description,
                    label: option.description,
                }))
            )
                }
                onOnSelect={(e) => handleResidence(e)}
            />
            <OmFormDropdownFieldWrapper
                id="region"
                name="region"
                useNativeMobileDropdown
                required
                size="full"
                formId="schemeDetailsForm"
                placeholder="Region"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.region}
                state={isInputValid(accidentData?.schemeDetails?.region)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.region) === 'error' ? 'Please select your region' : undefined }
                options={
                    referenceRegions &&
            JSON.stringify(
                referenceRegions.REGIONS.map((option) => ({
                    value: option.fullcode,
                    label: option.description,
                }))
            )
                }
                onOnSelect={(e) => updateAccidentData({ region: e.detail.value }, 'schemeDetails') }
            />
            <OmFormInputFieldWrapper
                label="Street address"
                id="street_address"
                name="street_address"
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                isSensitive
                placeholder="e.g. 33 Barlow Street"
                value={accidentData?.schemeDetails?.street_address}
                state={isInputValid(accidentData?.schemeDetails?.street_address)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.street_address) === 'error' ? 'Please enter your street address'  : undefined}
                onPersist={(e) => handleChange(e)}
            />
            <OmFormInputFieldWrapper
                label="City"
                id="city"
                name="city"
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                isSensitive
                placeholder="e.g. Accra"
                value={accidentData?.schemeDetails?.city}
                state={isInputValid(accidentData?.schemeDetails?.city)}
                errorMessage={isInputValid(accidentData?.schemeDetails?.city) === 'error' ? 'Please enter your city'  : undefined}
                onPersist={(e) => handleChange(e)}
            />
            <OmFormInputFieldWrapper
                label="Postal code"
                id="postal_code"
                name="postal_code"
                labelAlwaysShow={true}
                required
                formId="schemeDetailsForm"
                size="full"
                align="left"
                type="text"
                validationDelay={DEFAULT_VALIDATION_DELAY}
                isSensitive
                placeholder="e.g. 00233"
                value={accidentData?.schemeDetails?.postal_code}
                onPersist={(e) => handleChange(e)}
            />

            <OmFormDropdownFieldWrapper
                id="agentCode"
                name="agentCode"
                useNativeMobileDropdown
                className="inline-field"
                size="full"
                formId="schemeDetailsForm"
                placeholder="Agent Code (Optional)"
                align="left"
                type="text"
                value={accidentData?.schemeDetails?.agentCode}
                options={
                    agents &&
                JSON.stringify(
                    agents.AGENTS.map((option) => ({
                        value: option.name,
                        label: `${option.AgentNoCode} (${option.name})`,
                    }))
                )
                }
                onOnSelect={(e) => updateAccidentData({ agentCode: e.detail.value }, 'schemeDetails') }
            />

            <OmModalLoader
                slot="loading-modal"
                loaderText="Please wait..."
                verticalAlignMiddle={true}
                open={accidentData?.schemeDetails?.loading}
            />
        
        </>
    );
};

export default SchemeDetailsSection;
