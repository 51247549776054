import { flattenObject, objectToFormData,relationshipAcronym, getEmergentPayCredentials, getSlamsBaseURL, getAgeValue, OM_API_BASE_URL } from '../../../../ghana/utils/personal-accident-utils/util';

const generateBearerToken = async (clientId, clientSecret) => {
    try {
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/auth/tokenRequest?client_id=${clientId}&client_secret=${clientSecret}`,
            {
                method: 'POST',
                headers: {
                }
            }
        );

        const data = await response.json();
        return data?.access_token;
    } catch (error) {
        console.error('Error generating bearer token:', error);
        throw error;
    }
};

export const sendCreateClient = async (clientData) => {
    try {
        const clientId = getEmergentPayCredentials().client_id;
        const clientSecret = getEmergentPayCredentials().client_secret;

        const token = await generateBearerToken(clientId, clientSecret);
        
        const clientRegistartionData = flattenObject(clientData)
      
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/client/createClient`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`
                },
                body: objectToFormData(clientRegistartionData),
            }
        );

        const data = await response.json();
        data._token = token;
        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

export const sendCreatePolicy = async (token, beneficiaries, otherData) => {
    try {
   
        //====== re-arrange beneficiaries data =======
        let _newBeneficiaries = [];
        beneficiaries.map((b, i) => {
            let p = {
                'id': i,
                'Names': `${b.firstname} ${b.OtherName}`,
                'relationship': relationshipAcronym(b.Relationship),
                'perc_alloc': b.perc_alloc,
                'birth_date': b.birth_date,
                'telephone': '000000000',
                'address': 'N/A',
                'age': getAgeValue(b.birth_date),
                'gender': b.gender === 'Male' ? 'M' : 'F',
            }
            _newBeneficiaries.push(p)
        })

        const policyData = new FormData();
        _newBeneficiaries.forEach((_newBeneficiaries, index) => {
            for (let key in _newBeneficiaries) {
                key != 'id' && policyData.append(`beneficiary_data[${index}][${key}]`, _newBeneficiaries[key]);
            }
        });


        policyData.append('client_number', otherData?.client_number)
        policyData.append('plan_code', otherData?.plan_code)
        policyData.append('agent', otherData?.agent)
        policyData.append('pay_mode', otherData?.pay_mode)
        policyData.append('CommencementDate', otherData?.CommencementDate)
        policyData.append('term_of_policy', otherData?.term_of_policy)
        policyData.append('maturity_age', otherData?.maturity_age)
        policyData.append('modal_prem', otherData?.modal_prem)
        policyData.append('basic_premium', otherData?.basic_premium)
        policyData.append('with_dependants', otherData?.with_dependants)
        policyData.append('with_rider', otherData?.with_rider)
        policyData.append('maturity_date', otherData?.maturity_date)
        policyData.append('status_date', otherData?.status_date)
        policyData.append('pay_method', otherData?.pay_method)
        policyData.append('telco', otherData?.telco)
        policyData.append('momo_no', otherData?.momo_no)
        policyData.append('IsClientCurrentlyInGoodHealth', otherData?.IsClientCurrentlyInGoodHealth)
        policyData.append('IsClientHavingExistingPAInsurance', otherData?.IsClientHavingExistingPAInsurance)
        policyData.append('DoesClientHoldExistingInsurance', otherData?.DoesClientHoldExistingInsurance)
        policyData.append('IsClientInvolvedInDangerousHobbies', otherData?.IsClientInvolvedInDangerousHobbies)
        policyData.append('IllnessDetails', otherData?.IllnessDetails)
        policyData.append('is_smoker', otherData?.is_smoker)
        policyData.append('sum_assured', otherData?.sum_assured)

        
        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/createPolicy`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    
                },
                body: policyData,
            }
        );

        const data = await response.json();

        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

export const updatePaymentStatusForQA = async (policy_no) => {

    const clientId = getEmergentPayCredentials().client_id;
    const clientSecret = getEmergentPayCredentials().client_secret;

    const token = await generateBearerToken(clientId, clientSecret);

    const response = await fetch(
        `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/updatePaymentStatus?proposal_no=${policy_no}`,
        {
            method: 'POST',
            headers: {Authorization: `Bearer ${token}`, }
        }
    );
    const data = await response.json();
    console.log('updating...', data);
    if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
        // update endpoint to STATUS = PAID
        console.log('UPDATE PAYMENT::',response);    
    }
    return data;
}

export const checkIfPolicyIsPaid = async (policy_no) => {
    try {

        const acessToken = await generateBearerToken(getEmergentPayCredentials().client_id, getEmergentPayCredentials().client_secret);

        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/policy/get-proposal-payment-status?proposal_no=${policy_no}`,
            {
                method: 'GET',
                headers: {Authorization: `Bearer ${acessToken}`, }
            }
        );

        const data = await response.json();

        if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
            // update endpoint to STATUS = PAID
            await updatePaymentStatusForQA(acessToken, policy_no);
        }

        return data;

    } catch (error) {
        console.error('Request Error:', error);
    }
}

export const getAllOccupationFromSlams = async () => {
    try {
        const clientId = getEmergentPayCredentials().client_id;
        const clientSecret = getEmergentPayCredentials().client_secret;

        const token = await generateBearerToken(clientId, clientSecret);

        const response = await fetch(
            `${getSlamsBaseURL()}/oldmutual_dms_api/api/client/getAllOccupations`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );

        const data = await response.json();
        // console.log('OCCUPATIONS DATA::', data)
        return data?.occupations;
    } catch (error) {
        console.error('Error gettting occupations:', error);
        throw error;
    }
};

// Hubspot creating a contact
export const hubspotCreateContact = async (payload) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/create-contact?email=${payload.email}&firstname=${payload.firstname}&lastname=${payload.lastname}&phone=${payload.phone}&hubspot_owner_id=${payload.hubspot_owner_id}`,
            {
                method: 'POST',
            }
        );

        const data = await response.json();
        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot updating a contact
export const hubspotUpdateContact = async (payload, contactId) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/update-contact/${contactId}?email=${payload.email}&firstname=${payload.firstname}&lastname=${payload.lastname}&phone=${payload.phone}&hubspot_owner_id=${payload.hubspot_owner_id}`,
            {
                method: 'PATCH',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot get a contact by Email
export const hubspotGetContact = async (email) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/get-contact/${email}`,
            {
                method: 'GET',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot creating a deal
export const hubspotCreateDeal = async (payload) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/create-deal?amount=${payload.amount}&closedat=${payload.closedate}&dealname=${payload.dealname}&pipeline=${payload.pipeline}&dealstage=${payload.dealstage}&hubspot_owner_id=${payload.hubspot_owner_id}&dealtype=${payload.dealtype}&plan_name=${payload.plan_name}&source_of_deduction=${payload.source_of_deduction}&proposal_policy_number=${payload.proposal_policy_number}`,
            {
                method: 'POST',
            }
        );
        const data = await response.json();
        return data;
    
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot updating a deal
export const hubspotUpdateDeal = async (payload, dealId) => {
    try {
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/update-deal/${dealId}`,
            {
                method: 'PATCH',
                body: JSON.stringify(payload),
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};

// Hubspot assiign a contact to a deal
export const hubspotAttachContactToDeal = async (dealId, contactId) => {
    try {
  
        const response = await fetch(`${OM_API_BASE_URL}/hubspot/assign-contact-to-deal/${dealId}/${contactId}`,
            {
                method: 'PUT',
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
};