module.exports.REGIONS = [
    { fullcode: 'Ahafo Region (formerly part of Brong-Ahafo Region)', description: 'Ahafo Region (formerly part of Brong-Ahafo Region)', id: 15 },  
    { fullcode: 'Ashanti Region', description: 'Ashanti Region', id: 1 },
    { fullcode: 'Bono East Region (formerly part of Brong-Ahafo Region)', description: 'Bono East Region (formerly part of Brong-Ahafo Region)', id: 7 },
    { fullcode: 'Bono Region (formerly part of Brong-Ahafo Region)', description: 'Bono Region (formerly part of Brong-Ahafo Region)', id: 14 },
    { fullcode: 'Central Region', description: 'Central Region', id: 10 },
    { fullcode: 'Eastern Region', description: 'Eastern Region', id: 6 },
    { fullcode: 'Greater Accra Region', description: 'Greater Accra Region', id: 2 },
    { fullcode: 'Northern Region', description: 'Northern Region', id: 16 },
    { fullcode: 'North East Region (created from Northern Region)', description: 'North East Region (created from Northern Region)', id: 3 },
    { fullcode: 'Oti Region (created from Volta Region)', description: 'Oti Region (created from Volta Region)', id: 11 },
    { fullcode: 'Savannah Region (created from Northern Region)', description: 'Savannah Region (created from Northern Region)', id: 12 },
    { fullcode: 'Upper East Region', description: 'Upper East Region', id: 4 },
    { fullcode: 'Upper West Region', description: 'Upper West Region', id: 5 },
    { fullcode: 'Volta Region', description: 'Volta Region', id: 9 },
    { fullcode: 'Western Region', description: 'Western Region', id: 8 },
    { fullcode: 'Western North Region (created from Western Region)', description: 'Western North Region (created from Western Region)', id: 13 },
];