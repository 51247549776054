import React from 'react';
import { OmApplicationPage } from '@om/component-library-react';
import ApplicationLayout from '../../../../layouts/ApplicationLayout';
import ApplicationWizardHeader from '../applications/personal-accident/ApplicationWizardHeader';
import ApplicationWizardFooter from '../applications/personal-accident/ApplicationWizardFooter';
import { PersonalAccidentProvider } from '../applications/personal-accident/context/PersonalAccidentContext';
import Outlet from '../applications/personal-accident/pages/Outlet';

const PersonalAccidentPage = () => {
    return (
        <PersonalAccidentProvider>
            <ApplicationLayout>
                <OmApplicationPage>
                    <div slot="header">
                        <ApplicationWizardHeader callMeBack={{}} />
                    </div>
                    <div slot="content-no-sidebar">
                        <Outlet />
                    </div>
                    <div slot="footer">
                        <ApplicationWizardFooter />
                    </div>
                </OmApplicationPage>
            </ApplicationLayout>
        </PersonalAccidentProvider>
    );
};

export default PersonalAccidentPage;
