import { REGIONS } from '../../applications/personal-accident/constants/refeferenceData/ghanaRegions';
import { OCCUPATIONS } from '../../applications/personal-accident/constants/refeferenceData/occupation';
import { INPUT_STATE } from '../../configs/travelsure-configs/config';
import { AGENTS } from '../../applications/personal-accident/constants/refeferenceData/agents';

export const isBeneficiaryEntryNew = (id, arr) => {
    return arr.some((obj) => obj.id !== id);
};

export const getBeneficiaryIndex = (id, arr) => {
    return arr.findIndex((obj) => obj.id === id);
};

export const isInputValid = (val) => {
    if (val === null || (val !== undefined && val.toString().trim() === '' && val.toString().trim().length < 2)) {
        return INPUT_STATE.ERROR;
    } else if (val === undefined || !val) {
        return INPUT_STATE.UNTOUCHED;
    }
    return INPUT_STATE.VALID;
};

export const areAllInputsInvalid = (obj) => {
    return (
        obj &&
    Object.values(obj).every((val) => isInputValid(val) === INPUT_STATE.VALID)
    );
};

export const sumPercentageAllocationValues = (array) => {
    return array.reduce((total, obj) => {
        const age = parseInt(obj?.perc_alloc);
        if (!isNaN(age)) {
            return total + age;
        }
        return total;
    }, 0);
};


export const evenlySplitPercentageAllocation = (array) => {
    const length = array.length;
    const percAlloc = Math.floor(100 / length); // Calculate the base percentage allocation
    const remainder = 100 % length; // Calculate the remainder
    // Distribute the base percentage allocation among the array objects
    const result = array.map((obj, index) => ({
        ...obj,
        perc_alloc: percAlloc + (index < remainder ? 1 : 0),
    }));
    return result;
};

export const isValidGhanaCardNumber = (input) => {
    const regex = /^GHA-\d{9}-\d$/;
    return regex.test(input);
};

export const isValidDriversLicenseNumber = (input) => {
    const regex = /^[A-Z]{3}-\d{8}-\d{5}$/;
    return regex.test(input);
};

export const isValidDriversLicenseRefNumber = (input) => {
    const regex = /^\d{8}[A-Z]\d$/;
    return regex.test(input);
};
export const isValidPassportID = (input) => {
    const regex = /^[A-Z]{1}[0-9]{7}$/;
    return regex.test(input);
};

export const isValidVoterID = (input) => {
    const regex = /^\d{10}$/;
    return regex.test(input);
};

export const isValidSsnit = (input) => {
    const regex = /^\[A-Z]\d{12}$/;
    return regex.test(input);
};


export const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const isValidNumbers = (phone) => {
    const regex = /^([+]\d{2})?\d{9}$/;
    return regex.test(phone);
};

export const isValidName = (name) => {
    const regex = /^[a-zA-Z\s-]+$/;
    return regex.test(name);
};

export const isUserAbove15YearsOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 15;
}

export const isUserAbove0YearOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 0;
}

export const isUserAbove70YearOfBirth = (dateValue) =>{
    const today = new Date();
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return ageInYears >= 70;
}

export const isDateInPast = (dateValue) => {
  
    const givenDate = new Date(dateValue)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if(givenDate < today){
        //yes userDate is in the past
        return true;
    }else {
        // is in the future
        return false;
    }
}

export const isDateInPastQuote = (dateValue, compareDate) => new Date(compareDate) < new Date(dateValue);


export const flattenObject = (obj) => {
    const flattened = {};
    function flatten(obj) {
        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flatten(obj[key], key);
            } else {
                flattened[key] = obj[key];
            }
        }
    }
    flatten(obj);
    return flattened;
}

export const  objectToFormData = (obj) => {
    const formData = new FormData();

    function appendFormData(data, prefix) {
        if (Array.isArray(data)) {
            data.forEach((value, index) => {
                const arrayKey = `${prefix}[${index}]`;
                appendFormData(value, arrayKey);
            });
        } else if (typeof data === 'object' && data !== null) {
            Object.entries(data).forEach(([key, value]) => {
                const nestedKey = prefix ? `${prefix}.${key}` : key;
                appendFormData(value, nestedKey);
            });
        } else {
            formData.append(prefix, data);
        }
    }
    appendFormData(obj);
    return formData;
}

export const objectArrayToFormData = (arr) => {
    const formData = new FormData();

    arr.forEach((arr, index) => {
        for (let key in arr) {
            key != 'id' && formData.append(
                `benef_data[${index}][${key}]`,
                arr[key]
            );
        }
    });

    return formData;
}

export const Redirect = (url) => {
    return new Promise((resolve, reject) => {
        if (typeof window !== 'undefined') {
            window.location.replace(url);
            resolve();
        } else {
            reject(new Error('Window object is not available..'));
        }
    });
};

export const getSlamsBaseURL = () => {
    //getting the base url from the browser
    return process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-dms.oldmutual.com.gh:443' : 'https://prod-dms.oldmutual.com.gh';
}

export const relationshipAcronym = (relationship) => {
    let r = null;
    if(relationship == 'Son'){
        r = 'SN';
    }else if(relationship == 'Daughter'){
        r = 'DT';
    }else if(relationship == 'Aunt'){
        r = 'AN';
    }else if(relationship == 'Brother'){
        r = 'BR';
    }else if(relationship == 'Cousin'){
        r = 'CS';
    }else if(relationship == 'Father'){
        r = 'FA';
    }else if(relationship == 'Mother'){
        r = 'MO';
    }else if(relationship == 'Sister'){
        r = 'SS';
    }else if(relationship == 'Father in-law'){
        r = 'FI';
    }else if(relationship == 'Mother in-law'){
        r = 'MI';
    }else if(relationship == 'Spouse'){
        r = 'SP';
    }else if(relationship == 'Brother in-law'){
        r = 'BRI';
    }else if(relationship == 'Sister in-law'){
        r = 'SSI';
    }else if(relationship == 'Daughter in-law'){
        r = 'DTI';
    }else if(relationship == 'Grand Daughter'){
        r = 'GD';
    }else if(relationship == 'Grand Mother'){
        r = 'GR';
    }else if(relationship == 'Nephew'){
        r = 'NP';
    }else if(relationship == 'Niece'){
        r = 'NI';
    }else if(relationship == 'Uncle'){
        r = 'UN';
    }else if(relationship == 'Wife'){
        r = 'WF';
    }else if(relationship == 'Son in-law'){
        r = 'SNI';
    }else if(relationship == 'Neighbour'){
        r = 'NB';
    }
    return r;
}

export const getEmergentPayCredentials = () => {
    return {
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/personal-accident' : 'https://www.oldmutual.com.gh/personal-accident',
        payment_url: 'https://test-payment.oldmutual.com.gh/ProcessPayment', // used test because  the payment company do not  have a  live env as  oof now so test uses prod
        // client_id: process.env.GATSBY_DMS_CLIENT_ID,
        // client_secret: process.env.GATSBY_DMS_CLIENT_SECRET
        client_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '9275f1fa-11de-4482-a8fd-fd5e0c4579d2' : '9915ea80-36e6-4ec1-8e47-5834c392dd7d',
        client_secret: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'pIMsFbwmFjWwYZF4MGZqlBBRDBlqPycGiazl6El6' : 'W4A9DvqpOSmeTqXoY0OwINWf7my0JQ7xdHeldRrR'
    }
}

export const generateString = (length) => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const stripSystemEmojis = (data) => {
    return data
        .replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,'')
        .replace(/\s+/g, ' ')
        .trim();
}

export const getAgeValue = (dateValue) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const userDate = new Date(dateValue);
    const ageInMillis = today - userDate
    const ageInYears = ageInMillis / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(ageInYears);
}

export const addWeeksToDate = (date, weeks) => {
    date.setDate(date.getDate() + 7 * weeks);
    return date;
}

export const calculateSumAssuredPremium = (sum_assured, occupation_class) => {
    if(occupation_class === 'Class 1'){
        return (parseFloat(sum_assured) * 0.01).toFixed(2)
    }else if(occupation_class === 'Class 2'){
        return (parseFloat(sum_assured) * 0.015).toFixed(2)
    }else if(occupation_class === 'Class 3'){
        return (parseFloat(sum_assured) * 0.018).toFixed(2)
    }else if(occupation_class === 'Class 4'){
        return (parseFloat(sum_assured) * 0.02).toFixed(2)
    }else{
        return (parseFloat(sum_assured) * 0.02).toFixed(2);
    }
}

export const formatOccupationClass = (val) => {
    if(val == 'Class 1 (Professional, Administrative and Clerical, Commercial Travelers and certain non-hazardous shopkeepers and shop assistants not engaging in manual work or delivery or using tools)'){
        return 'Class 1';
    }else if(val == 'Class 2 (Master tradesman (supervising only and not engaged in manual work) Shop assistants/other than those in class 1 not using tools or delivery)'){
        return 'Class 2';
    }else if(val == 'Class 3 (Veterinary Surgeons, Farmers (superintending only), Master Builder and Contractors not using wood working machinery, engineers (supervising only))'){
        return 'Class 3';
    }else if(val == 'Class 4 (Farmer (working), transport workers Printers, garage workers and personnel engaged in light industry generally using non- hazardous machinery.)'){
        return 'Class 4';
    }else{
        return 'N/A';
    }
}

export const getGenderReferential = (data) => {
    if(data === 'Male'){
        return 'M';
    }else if(data === 'Female'){
        return 'F';
    }else{
        return 'X';
    }
}
export const getIDReferential = (data) => {
    if(data === 'Ghana Card Number'){
        return 'ID';
    }else{
        return 'VT';
    }
}
export const getClassReferential = (data) => {
    if(data === 'Class 1 (Professional, Administrative and Clerical, Commercial Travelers and certain non-hazardous shopkeepers and shop assistants not engaging in manual work or delivery or using tools)'){
        return '001';
    }else if(data === 'Class 2 (Master tradesman (supervising only and not engaged in manual work) Shop assistants/other than those in class 1 not using tools or delivery)'){
        return '002';
    }else if(data === 'Class 3 (Veterinary Surgeons, Farmers (superintending only), Master Builder and Contractors not using wood working machinery, engineers (supervising only))'){
        return '003';
    }else if(data === 'Class 4 (Farmer (working), transport workers Printers, garage workers and personnel engaged in light industry generally using non- hazardous machinery.)'){
        return '004';
    }else{
        return '005';
    }
}
export const getTitleReferential = (data) => {
    switch (data) {
    case 'Miss':
        return '1';
        break;
        
    case 'Mr':
        return '2';
        break;

    case 'Mrs':
        return '3';
        break;

    case 'Dr':
        return '4';
        break;

    case 'Rev':
        return '6';
        break;

    case 'Prof':
        return '5';
        break;

    case 'Eng':
        return '7';
        break;

    case 'Pastor':
        return '8';
        break;

    case 'Hon':
        return '9';
        break;

    case 'Alhaji':
        return '10';
        break;

    case 'Ms':
        return '11';
        break;

    case 'Madam':
        return '12';
        break;
            
    default:
        return '0';
        break;
    }
}
export const getMaritalReferential = (data) => {
    if(data === 'Single'){
        return '4';
    }else if(data === 'Married'){
        return '2';
    }else if(data === 'Divorced'){
        return '1';
    }else if(data === 'Widower'){
        return '3';
    }else if(data === 'Widow'){
        return '5';
    }else{
        return '6';
    }
}
export const getOccupationReferential = (data) => {
   
    let filteredOccupation = OCCUPATIONS.filter((_occupation) => {
        return _occupation.occupation_name === data;
    });

    return filteredOccupation[0]?.occupation_code;
}

export const getRegionReferential = (data) => {
    
    let region = REGIONS.filter((_region) => {
        return _region.description === data;
    })
    return region[0].id;
}

export const stripZeroPhoneNumber = (phonenumber) => {
    if (phonenumber.startsWith('0'))
    {
        return phonenumber = phonenumber.substr(1);
    }
    return phonenumber;
}

export const getHubtelCredentials = () => {
    return {
        return_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://d1b2sh1ghml15h.cloudfront.net/travel-insurance' : 'https://www.oldmutual.com.gh/travel-insurance',
        payment_url: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/api/v2/hubteldirectdebitpayment/initializev2':'https://payintegrations.oldmutual.com.gh/api/v2/hubteldirectdebitpayment/initializev2' ,
        payment_verifyOTP: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/api/v1/hubteldirectdebitpayment/verify-otp':'https://payintegrations.oldmutual.com.gh/api/v1/hubteldirectdebitpayment/verify-otp' ,
        baseURL: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'https://test-payintegrations.oldmutual.com.gh/':'https://payintegrations.oldmutual.com.gh/' ,
        API_KEY: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? 'ApiKey ty2h2k64i4z7glfg00wyzzuqw7tzzk7j' : 'ApiKey E6adPETAkDZl7hT0mN1MhTx8jwqCECL0__'
    }
}

export const hubtelNetworkType = (telco='MTN') => {
    if(telco == 'MTN'){
        return 0;
    }else if(telco == 'Vodafone'){
        return 1;
    }else if(telco == 'AirtelTigo'){
        return 2;
    }
}

export const getAgentsReferential = (data) => {
    const agents = AGENTS;

    let filteredAgent = agents.filter((_agent) => {
        return `${_agent.AgentNoCode} (${_agent.name})` === data;
    });

    return filteredAgent[0]?.id;
}

export const removeLocalStorage = () => {
    localStorage.removeItem('accidentData');
    localStorage.removeItem('accidentActivePageIndex');
    localStorage.removeItem('beneficiaryListDataAccident');
    localStorage.removeItem('OMPA_policy_no');
}

export const OM_API_BASE_URL = process.env.GATSBY_ENVIRONMENT_NAME === 'prod' ? 'https://goals-api.my.oldmutual.co.za/om-api/mvest' : 'https://goals-qa.digital.omapps.net:8080/wa/om-api/mvest';

export const pushToGAonNext = (_eventName=null, _payload=null) => {
    const index = localStorage.getItem('accidentActivePageIndex');

    let payload = {accidentData: index == 4 || index == 5 ? JSON.parse(localStorage.getItem('beneficiaryListDataAccident')) : JSON.parse(localStorage.getItem('accidentData')), accidentActivePageIndex: index}

    if(index == 0){
        window.dataLayer.push({'event' : 'journey_begin', 'response' : payload })
    }else if(index == 1){
        window.dataLayer.push({'event' : 'add_personal_info', 'response' : payload })
    }else if(index == 2){
        window.dataLayer.push({'event' : 'add_health_info', 'response' : payload })
    }else if(index == 3){
        window.dataLayer.push({'event' : 'add_policy_details', 'response' : payload })
    }else if(index == 4 || index == 5){
        window.dataLayer.push({'event' : 'add_beneficiary', 'response' : payload })
    }else{
        window.dataLayer.push({'event' : _eventName, 'response' : _payload })
    }
}
