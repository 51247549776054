import React, { useContext, useState } from 'react';
import { OmModal } from '@om/component-library-react';
import OmButton from '../../../../../../../components/forms/OmButton';
import {OmRadioButtonGroup, OmModalLoader} from '@om/component-library-react';
import { Redirect } from '../../../../utils/personal-accident-utils/util';
import { hubtelPaymentVerifyTransaction, initiateHubtelPayment, initiatePayment } from '../../server/pay';
import { PersonalAccidentContext } from '../../context/PersonalAccidentContext';
import HubtelVerify from './HubtelVerify';

const SelectTelco = ({ open, onCloseHandler }) => {

    const { navigateContinue } = useContext(PersonalAccidentContext);

    const [paymentProvider, setPaymentProvider] = useState('Hubtel')
    const [telco, setTelco] = useState(undefined)
    const [clientRefId, setClientRefId] = useState(null)
    const [otpPrefix, setOtpPrefix] = useState(null)
    const [loading, setLoading] = useState(false)
    const [verifyOTPModal, setVerifyOTPModal] = useState(false)


    const onSubmitPressed = async () => {
        if(paymentProvider && telco){
            setLoading(true)
            localStorage.setItem('OMPA_Telco', telco)
            
            if(paymentProvider === 'Hubtel'){
                const response = await initiateHubtelPayment()
                if(response.successful && response.data.preApprovalStatus === 'APPROVED'){
                    //user will receive USSD prompt to complete process

                    //CHECK IF PAYMENT STATUS IS PAID
                    setTimeout(async () => {
                        const debitCheck = await hubtelPaymentVerifyTransaction(response.data.clientReferenceId)

                        setLoading(false)
                        if(debitCheck?.data?.transaction?.paid == false){
                        // error occured on customer side during deduction
                            alert(`Error: ${debitCheck.data.description}`)
                        }else{
                        //payment was successful
                            alert('Payment successful!')
                            onCloseHandler()
                            navigateContinue()
                        }
                    }, 40000)

                }else if(response.successful && response.data.otpPrefix !== null){
                    //user will receive OTP prompt to complete process
                    setClientRefId(response.data.clientReferenceId)
                    setOtpPrefix(response.data.otpPrefix)
                    setLoading(false)
                    toggleOTPModal()

                }else if(response.successful && response.data.preApprovalStatus === 'PENDING'){
                    //user is yet to receive OTP from hubtel to pass preapproval
                    setLoading(false)
                    alert(`${response.message}. You will receive an SMS to complete preapproval process!`)

                }else{
                    setLoading(false)
                    alert(response.message || ' Error processing your payment!')
                }
            }else{
                await emergentPayPortal()
                setLoading(false)
                onCloseHandler()
            }
        }else{
            setLoading(false)
            alert('Select both your Payment Provider and MoMo Network to continue!')
        }
    }

    const emergentPayPortal = async () => {
        try {
            if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
                await Redirect('https://d1b2sh1ghml15h.cloudfront.net/personal-accident/?status_code=1&status_message=Successful&trans_ref_no=1179881&order_id=OMPF/MV/GHA-14443342322234311&signature=7KZwcMLIpokAMWxRgUjSA%2bjZA%2bE%3d&payment_mode=MTN&reason=UNKNOWN');
            }else{
                await initiatePayment()
            }
        } catch (error) {
            console.error('Redirection failed:', error);
        }
    };

    const toggleOTPModal = () => setVerifyOTPModal(!verifyOTPModal)


    return (

        <OmModal
            open={open}
            isCentered="true"
            overlayName="cancel-eclaim-journey">
            <div slot="modal-content" className="cancel-modal">

                <p className="cancel-modal-title">Select your payment option and provider to continue</p>
                
                <OmRadioButtonGroup
                    id="payment_provider"
                    name="payment_provider"
                    vertical={true}
                    groupName="payment_provider"
                    value={paymentProvider}
                    onOnSelection={(e) => setPaymentProvider(e.detail)}
                    label="Select payment provider"
                    labelAlwaysShow={true}>
                    <ul slot="list-items">
                        <li value="Hubtel">Hubtel</li>
                        {/* <li value="EmergentPay">Emergent Pay</li> */}
                    </ul>
                </OmRadioButtonGroup>

                <OmRadioButtonGroup
                    id="telco"
                    name="telco"
                    vertical={true}
                    groupName="telco"
                    value={telco}
                    onOnSelection={(e) => setTelco(e.detail)}
                    label="Select your mobile money network provider"
                    labelAlwaysShow={true}>
                    <ul slot="list-items">
                        <li value="MTN">MTN Ghana</li>
                        <li value="Vodafone">Vodafone Ghana</li>
                        {/* <li value="AirtelTigo">AirtelTigo</li> */}
                    </ul>
                </OmRadioButtonGroup>

                {paymentProvider == 'Hubtel' ? <p><strong>Ensure you have enough money on your MoMo Wallet to proceed!</strong></p> : ''}

                <div className="cancel-modal-buttons">
                    <OmButton
                        type="primary"
                        text={`${loading ? 'Processing...' : 'Proceed to payment'}`}
                        size="small"
                        onClick={onSubmitPressed}
                        disabled={loading}
                    />
                </div>
            </div>
            <OmModalLoader
                slot="loading-modal"
                loaderText="Please wait..."
                verticalAlignMiddle={true}
                open={false}
            />

            {verifyOTPModal ? <HubtelVerify otpPrefix={otpPrefix} clientReferenceId={clientRefId} toggleModal={toggleOTPModal}  /> : ''}
        </OmModal>
    );
};

export default SelectTelco;