import React, { useContext, useEffect, useState } from 'react';

import OmButton from '../../../../../components/forms/OmButton';
import { OmApplicationWizardFooter } from '@om/component-library-react';
import { PersonalAccidentContext } from './context/PersonalAccidentContext';
import { PAGE_INDICES } from './constants/routing';
import { sendCreatePolicy, checkIfPolicyIsPaid, updatePaymentStatusForQA, sendCreateClient, hubspotGetContact, hubspotCreateContact, hubspotCreateDeal, hubspotAttachContactToDeal, hubspotUpdateContact } from './server/api';
import { addWeeksToDate, areAllInputsInvalid, calculateSumAssuredPremium, formatOccupationClass, generateString, getAgeValue, getClassReferential, getGenderReferential, getIDReferential, getMaritalReferential, getOccupationReferential, getRegionReferential, getTitleReferential, pushToGAonNext, sumPercentageAllocationValues, getAgentsReferential} from '../../../ghana/utils/personal-accident-utils/util';
import useUrlParams from './hooks/useUrlParams';


const ApplicationWizardFooter = () => {

    const urlParams = useUrlParams();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {
        accidentActivePageIndex,
        hasAcceptedTermsAndCondition,
        navigateContinue,
        navigatePrevious,
        accidentData,
        beneficiaryListDataAccident, updateAccidentData, togglePaymentModal
    } = useContext(PersonalAccidentContext);

    let continueText = accidentActivePageIndex === PAGE_INDICES.REVIEW_APPLICATION ? (urlParams?.status_code == 1 ? (isSubmitting ? 'PROCESSING...' : 'MAKE PAYMENT') : 'SUBMIT & PAY') : ( beneficiaryListDataAccident.length >= 1 && accidentActivePageIndex === PAGE_INDICES.BENEFICIARIES ? 'CONTINUE' : 'CONTINUE');
    // const continueText = t_activePageIndex === PAGE_INDICES.REVIEW_APPLICATION ? "SUBMIT" : ( beneficiaryListDataAccident.length >= 1 && t_activePageIndex === PAGE_INDICES.BENEFICIARIES ? "SPLIT PERCENTAGE" : "CONTINUE");
    const isLastPage = accidentActivePageIndex === PAGE_INDICES.SUCCESS;

    useEffect(() => {
        if(accidentActivePageIndex === PAGE_INDICES.REVIEW_APPLICATION){
            checkPolicyStatus();
        }    
    },[accidentActivePageIndex])

    const checkPolicyStatus = async () => {
        updateAccidentData({ loading: false }, 'schemeDetails');
        if(localStorage.getItem('OMPA_policy_no')){
            setIsSubmitting(true)
            if(process.env.GATSBY_ENVIRONMENT_NAME == 'qa'){
                await updatePaymentStatusForQA(localStorage.getItem('OMPA_policy_no'))
                navigateContinue()
            }else{
                setIsSubmitting(true)
                const resp = await checkIfPolicyIsPaid(localStorage.getItem('OMPA_policy_no'));

                if(resp?.success == true){
                    navigateContinue()
                }else{
                    const queryString = window.location.search;
                    const UP = new URLSearchParams(queryString);
                
                    if(UP.get('status_code') == 1){
                        const updateResp = await updatePaymentStatusForQA(localStorage.getItem('OMPA_policy_no'))
                        if(updateResp?.success == true){
                            navigateContinue()
                        }else{
                            setIsSubmitting(false)
                        }
                    }
  
                    setIsSubmitting(false)
                }
            }
        }
    }

    const validateFormNextStep = () => {
        if (accidentActivePageIndex === PAGE_INDICES.START){
            return true;
        }else if (accidentActivePageIndex === PAGE_INDICES.PERSONAL_DETAILS) {
            let dataToCheck = {
                title : accidentData?.schemeDetails?.title,
                first_name: accidentData?.schemeDetails?.first_name,
                last_name: accidentData?.schemeDetails?.last_name,
                birth_date: accidentData?.schemeDetails?.birth_date,
                country_of_birth: accidentData?.schemeDetails?.country_of_birth,
                marital_status: accidentData?.schemeDetails?.marital_status,
                gender: accidentData?.schemeDetails?.gender,
                nationality: accidentData?.schemeDetails?.nationality,
                idType: accidentData?.schemeDetails?.idType,
                id_number: accidentData?.schemeDetails?.id_number,
                email_address: accidentData?.schemeDetails?.email_address,
                mobile_number: accidentData?.schemeDetails?.mobile_number,
                momo_number: accidentData?.schemeDetails?.momo_number,
                country_of_residence: accidentData?.schemeDetails?.country_of_residence,
                region: accidentData?.schemeDetails?.region,
                street_address: accidentData?.schemeDetails?.street_address,
                city: accidentData?.schemeDetails?.city,
                // postal_code: accidentData?.schemeDetails?.postal_code,
                occupation: accidentData?.schemeDetails?.occupation,
                occupation_class: accidentData?.schemeDetails?.occupation_class,
                politically_exposed: accidentData?.schemeDetails?.politically_exposed,
            }
            return areAllInputsInvalid(dataToCheck);
        }else if (accidentActivePageIndex === PAGE_INDICES.HEALTH_INFORMATION) {
            let dataToCheck = {
                do_you_smoke : accidentData?.schemeDetails?.do_you_smoke,
                good_health: accidentData?.schemeDetails?.good_health,
                existing_accident_insurance: accidentData?.schemeDetails?.existing_accident_insurance,
                existing_policy: accidentData?.schemeDetails?.existing_policy,
                involved_in_activities: accidentData?.schemeDetails?.involved_in_activities,
                // any_illness: accidentData?.schemeDetails?.any_illness,
            }
            return areAllInputsInvalid(dataToCheck);
        }else if (accidentActivePageIndex === PAGE_INDICES.POLICY_DETAILS) {
            let dataToCheck = {
                sum_assured : accidentData?.schemeDetails?.sum_assured,
                commencement_date: accidentData?.schemeDetails?.commencement_date
            }
            return areAllInputsInvalid(dataToCheck);
        }else if (accidentActivePageIndex === PAGE_INDICES.BENEFICIARIES) {
            for (let idx = 0; idx < beneficiaryListDataAccident.length; idx++) {
                const beneficiary = beneficiaryListDataAccident[idx];
                return areAllInputsInvalid(beneficiary)
            }
        } else if (accidentActivePageIndex === PAGE_INDICES.BENEFICIARIES_SPLIT) {
            for (let idx = 0; idx < beneficiaryListDataAccident.length; idx++) {
                const beneficiary = beneficiaryListDataAccident[idx];

                return areAllInputsInvalid(beneficiary) && sumPercentageAllocationValues(beneficiaryListDataAccident) == 100
            }
        } else if (accidentActivePageIndex === PAGE_INDICES.REVIEW_APPLICATION) {
            return hasAcceptedTermsAndCondition;
        }
        return;
    };

    const pay = () => togglePaymentModal()

    const submit = async () => {
        try {
            setIsSubmitting(true)
            if(urlParams?.status_code == 3 || urlParams?.status_code == 2){
                updateAccidentData({ loading: true }, 'schemeDetails');
                pay()
                return;
            }

            if(accidentData?.schemeDetails?.momo_number.length < 10){
                alert('Invalid MoMo number, payment requires valid number eg. 054 891 2451')
                setIsSubmitting(false)
                return;
            }

            //=== google analytics ===
            pushToGAonNext('begin_checkout', {currency: 'GHS', value: calculateSumAssuredPremium(accidentData?.schemeDetails?.sum_assured, formatOccupationClass(accidentData?.schemeDetails?.occupation_class)), items: [{ item_id: generateString(4), item_name: 'Personal Accident' }], accidentActivePageIndex: accidentActivePageIndex})

            updateAccidentData({ loading: true }, 'schemeDetails');
            const registerPayload = {
                surname: accidentData?.schemeDetails?.last_name,
                firstname: accidentData?.schemeDetails?.first_name,
                title: getTitleReferential(accidentData?.schemeDetails?.title),
                gender: getGenderReferential(accidentData?.schemeDetails?.gender),
                country_of_birth: accidentData?.schemeDetails?.country_of_birth,
                date_of_birth: accidentData?.schemeDetails?.birth_date,
                marital_status: getMaritalReferential(accidentData?.schemeDetails?.marital_status),
                id_type: getIDReferential(accidentData?.schemeDetails?.idType),
                id_number: accidentData?.schemeDetails?.id_number,
                mobile: accidentData?.schemeDetails?.mobile_number,
                email: accidentData?.schemeDetails?.email_address,
                address: accidentData?.schemeDetails?.city,
                occupation_class: getClassReferential(accidentData?.schemeDetails?.occupation_class),
                payment_method: 1,
                occupation: getOccupationReferential(accidentData?.schemeDetails?.occupation),
                country: accidentData?.schemeDetails?.country_of_residence,
                region: getRegionReferential(accidentData?.schemeDetails?.region),
                income_source: 3,
                is_politically_exposed: accidentData?.schemeDetails?.politically_exposed === 'Yes' ? 1 : 0,
                physical_address: `${accidentData?.schemeDetails?.street_address} ${accidentData?.schemeDetails?.postal_code}`,
                client_type: 2,
                client_resides_in_ghana: accidentData?.schemeDetails?.client_resides_in_ghana === 'Yes' ? 1 : 0,
                client_consent: 1,
                has_medical_condition: accidentData?.schemeDetails?.good_health  === 'Yes' ? 1 : 0,
            }
            // console.log('Registering payload::',registerPayload)
            
            const res = await sendCreateClient(registerPayload)
            // console.log('Registering response::',res)

            if (res?.success == true ){
       
                const policyPayload = {
                    client_number: res?.client_number.trim(),
                    plan_code: 8,
                    agent: 1,
                    pay_mode: 'SINGLE PREMIUM',
                    CommencementDate: accidentData?.schemeDetails?.commencement_date,
                    sum_assured: accidentData?.schemeDetails?.sum_assured,
                    term_of_policy: 12,
                    maturity_age: getAgeValue(accidentData?.schemeDetails?.birth_date) + 1,
                    modal_prem: calculateSumAssuredPremium(accidentData?.schemeDetails?.sum_assured, formatOccupationClass(accidentData?.schemeDetails?.occupation_class)),
                    basic_premium: accidentData?.schemeDetails?.sum_assured,
                    with_dependants: 0,
                    with_rider: 0,
                    maturity_date: new Date(addWeeksToDate(new Date(), 52)).toISOString().slice(0, 10),
                    status_date: new Date().toISOString().slice(0, 10),
                    pay_method: 1,
                    telco: '00465',
                    momo_no: accidentData?.schemeDetails?.mobile_number,
                    IsClientCurrentlyInGoodHealth: accidentData?.schemeDetails?.good_health === 'Yes' ? 1 : 0,
                    IsClientHavingExistingPAInsurance: accidentData?.schemeDetails?.existing_accident_insurance === 'Yes' ? 1 : 0,
                    DoesClientHoldExistingInsurance: accidentData?.schemeDetails?.existing_policy === 'Yes' ? 1 : 0,
                    IsClientInvolvedInDangerousHobbies: accidentData?.schemeDetails?.involved_in_activities === 'Yes' ? 1 : 0,
                    IllnessDetails: accidentData?.schemeDetails?.any_illness ? accidentData?.schemeDetails?.any_illness : 'N/A',
                    is_smoker: accidentData?.schemeDetails?.do_you_smoke === 'Yes' ? 1 : 0
                }
                if(accidentData?.schemeDetails?.agentCode){
                    policyPayload.agent = getAgentsReferential(accidentData?.schemeDetails?.agentCode)
                }
                // console.log('Create policy payload::',policyPayload)
                const policy_response = await sendCreatePolicy(res?._token, beneficiaryListDataAccident, policyPayload)
                // console.log('Create Policy response::',policy_response)
                if(policy_response?.success == true){
                    localStorage.setItem('OMPA_policy_no', policy_response?.proposal_no)

                    // ==== create deal on CRM =====
                    if(process.env.GATSBY_ENVIRONMENT_NAME === 'prod'){
                        await crmDeal(calculateSumAssuredPremium(accidentData?.schemeDetails?.sum_assured, formatOccupationClass(accidentData?.schemeDetails?.occupation_class)), policy_response?.proposal_no)
                    }
                    // ==== End of creating deal on CRM =====
                    //=== google analytics ===
                    pushToGAonNext('purchase', {currency: 'GHS', value: calculateSumAssuredPremium(accidentData?.schemeDetails?.sum_assured, formatOccupationClass(accidentData?.schemeDetails?.occupation_class)), items: [{ item_id: generateString(4), item_name: 'Personal Accident',transaction_id: policy_response?.proposal_no }], accidentActivePageIndex: accidentActivePageIndex})

                    pay()
                }else{
                    updateAccidentData({ loading: false }, 'schemeDetails');
                    alert('Error occured submitting policy details')
                    setIsSubmitting(false)
                }
        
            }else{
                updateAccidentData({ loading: false }, 'schemeDetails');
                alert('Error occured submitting client details')
                setIsSubmitting(false)
            }
        } catch (error) {
            updateAccidentData({ loading: false }, 'schemeDetails');
            setIsSubmitting(false)
            console.error('Submission failed:', error);
        }

    }

    const crmContact = async () => {
        if(localStorage.getItem('CRM_CONTACT_ID') == null || localStorage.getItem('CRM_CONTACT_ID') == undefined){
            const _crm = await hubspotGetContact(accidentData?.schemeDetails?.email_address)

            if(!_crm.id){
                let payload = {
                    email: accidentData?.schemeDetails?.email_address,
                    firstname: accidentData?.schemeDetails?.first_name,
                    lastname: accidentData?.schemeDetails?.last_name,
                    phone: accidentData?.schemeDetails?.mobile_number,
                    hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943'
                }
                const createCRMContact = await hubspotCreateContact(payload);
                localStorage.setItem('CRM_CONTACT_ID', createCRMContact?.id)
            }else{
                localStorage.setItem('CRM_CONTACT_ID', _crm?.id)
            }
        }else{
            let payload = {
                email: accidentData?.schemeDetails?.email_address,
                firstname: accidentData?.schemeDetails?.first_name,
                lastname: accidentData?.schemeDetails?.last_name,
                phone: accidentData?.schemeDetails?.mobile_number,
                hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943'
            }
            await hubspotUpdateContact(payload, localStorage.getItem('CRM_CONTACT_ID'))
        }
    }

    const crmDeal = async (amount, policyNumber) => {

        const closeDate = new Date();
        closeDate.setMonth(closeDate.getMonth() + 1);

        let crmPayload = {
            amount: amount,
            closedate: closeDate.toISOString(),
            dealname: `${accidentData?.schemeDetails?.first_name} ${accidentData?.schemeDetails?.last_name} - Personal Accident ${policyNumber}`,
            pipeline: 'default',
            dealstage: '376751587',
            hubspot_owner_id: process.env.GATSBY_ENVIRONMENT_NAME == 'qa' ? '1421520828' : '1327009943',
            dealtype: 'newbusiness',
            plan_name: 'Personal Accident',
            source_of_deduction: 'MoMo',
            proposal_policy_number: policyNumber,
            mobilephone: accidentData?.schemeDetails?.momo_number
        }

        const resp = await hubspotCreateDeal(crmPayload)
        // console.log('Deal Resp::', resp);

        // assign contact to deal
        if(resp?.id){
            await hubspotAttachContactToDeal(resp?.id, localStorage.getItem('CRM_CONTACT_ID'))
        } 
    }

    const nextActionButton = () => {
        if(accidentActivePageIndex === PAGE_INDICES.REVIEW_APPLICATION){
            submit()
        }else{
            pushToGAonNext()
            navigateContinue()
            if(accidentActivePageIndex === PAGE_INDICES.HEALTH_INFORMATION && process.env.GATSBY_ENVIRONMENT_NAME === 'prod'){
                crmContact()
            }
            
        }
    }

    return (
        !isLastPage && (
            <OmApplicationWizardFooter
                index={accidentActivePageIndex}
                totalCount="totalSteps"
            >
                <OmButton
                    slot="left"
                    onClick={() => navigatePrevious()}
                    type="text"
                    size="small"
                    text="Previous"
                    disabled={accidentActivePageIndex <= PAGE_INDICES.START}
                />

                <OmButton
                    disabled={!validateFormNextStep() || isSubmitting}
                    iconName="arrow_forward"
                    slot="right"
                    onClick={() => nextActionButton()}
                    type="primary"
                    size="small"
                    text={continueText}
                />
            </OmApplicationWizardFooter>
        )
    );
};

export default ApplicationWizardFooter;
