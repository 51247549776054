import React, { useContext } from 'react';
import './BeneficiariesSplitPage.scss';
import ApplicationBeneficiarySplitList from './ApplicationBeneficiarySplitList';
import { PersonalAccidentContext } from '../../context/PersonalAccidentContext';

const BeneficiariesSplitPage = () => {
    const { beneficiaryListDataAccident } = useContext(PersonalAccidentContext);
    return (
        <div className="mvest-beneficiaries-split">
            <div className="mvest-beneficiaries-split-wrapper">
                <h5 className="no-top-margin">
          Split your benefit <strong>between your beneficiaries</strong>
                </h5>

                <ApplicationBeneficiarySplitList
                    beneficiaryList={beneficiaryListDataAccident}
                />
            </div>
        </div>
    );
};

export default BeneficiariesSplitPage;
