import React, { useContext } from 'react';
import { PAGE_INDICES } from '../constants/routing';
import { PersonalAccidentContext } from '../context/PersonalAccidentContext';
import SchemeDetailsPage from './schemeDetailsPage/SchemeDetails';
import BeneficiariesPage from './beneficiariesPage/BeneficiariesPage';
import BeneficiariesSplitPage from './beneficiariesSplitPage/BeneficiariesSplitPage';
import ReviewPage from './reviewPage/ReviewPage';
import SuccessPage from './successPage/successPage';
import Health from './healthPage/health';
import Policy from './policyPage/policy';
import StartPage from './startPage/startPage';

const renderComponent = (action) => {
    switch (action) {
    case PAGE_INDICES.START:
        return <StartPage />;
    case PAGE_INDICES.PERSONAL_DETAILS:
        return <SchemeDetailsPage />;
    case PAGE_INDICES.HEALTH_INFORMATION:
        return <Health />;
    case PAGE_INDICES.POLICY_DETAILS:
        return <Policy />;
    case PAGE_INDICES.BENEFICIARIES:
        return <BeneficiariesPage />;
    case PAGE_INDICES.BENEFICIARIES_SPLIT:
        return <BeneficiariesSplitPage />;
    case PAGE_INDICES.REVIEW_APPLICATION:
        return <ReviewPage />;
    case PAGE_INDICES.SUCCESS:
        return <SuccessPage />;
    default:
        return null;
    }
};

const Outlet = () => {
    const { accidentActivePageIndex } = useContext(PersonalAccidentContext);
    return <>{renderComponent(accidentActivePageIndex)}</>;
};

export default Outlet;
