import React, { useState, useContext } from 'react';
import {
    OmApplicationBeneficiaryList,
    OmApplicationBeneficiarySplitCard,
    OmFormInputFieldWrapper,
} from '@om/component-library-react';
import { PersonalAccidentContext } from '../../context/PersonalAccidentContext';
import {
    evenlySplitPercentageAllocation,
    sumPercentageAllocationValues,
    isInputValid,
} from '../../../../../ghana/utils/personal-accident-utils/util';

const ApplicationBeneficiarySplitList = ({ beneficiaryList }) => {
    const {
        beneficiaryListDataAccident,
        updatebeneficiaryListDataAccident,
        isEvenSplit,
        splitEvenly,
    } = useContext(PersonalAccidentContext);
    const inputPersistHandler = (e, id) => {
        const newListData = beneficiaryListDataAccident.map((obj) => {
            if (obj?.id === id) {
                return { ...obj, [e.target.name]: e.detail };
            }
            return obj;
        });
        updatebeneficiaryListDataAccident(newListData);
    // console.log(beneficiaryListDataAccident);
    };
    const checkBoxChangedHandler = (arr) => {
        splitEvenly();
        updatebeneficiaryListDataAccident(evenlySplitPercentageAllocation(arr));
    // console.log(beneficiaryListDataAccident);
    };
    return (
        <OmApplicationBeneficiaryList
            evenSplit={isEvenSplit}
            totalPercentage={sumPercentageAllocationValues(beneficiaryList)}
            errorMessage={
                sumPercentageAllocationValues(beneficiaryList) !== 100
                    ? 'Your total should be 100%'
                    : undefined
            }
            //   state={beneficiaryListStatus}
            onCheckBoxChanged={() => checkBoxChangedHandler(beneficiaryList)}
        >
            <div slot="beneficiary-cards">
                {beneficiaryList &&
          beneficiaryList.map((beneficiary, index) => {
              return (
                  <OmApplicationBeneficiarySplitCard
                      id={index}
                      key={index}
                      number={index + 1}
                      name={beneficiary?.firstname}
                      surname={beneficiary?.OtherName}
                      // errorMessage={
                      //   sumPercentageAllocationValues(beneficiaryList) === 100
                      //     ? "Your total should be 100%"
                      //     : undefined
                      // }
                  >
                      <OmFormInputFieldWrapper
                          maxLength={3}
                          slot="percentage-input"
                          align="center"
                          name="perc_alloc"
                          keyboardMode="numeric"
                          value={beneficiary?.perc_alloc}
                          state={isInputValid(beneficiary?.perc_alloc)}
                          onPersist={(e) => inputPersistHandler(e, beneficiary?.id)}
                      />
                  </OmApplicationBeneficiarySplitCard>
              );
          })}
            </div>
        </OmApplicationBeneficiaryList>
    );
};

export default ApplicationBeneficiarySplitList;
