import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import {
    OmApplicationWizardHeader,
    OmApplicationProgressBar,
    OmIcon,
    Om1ColLayout,
} from '@om/component-library-react';
import OmButton from '../../../../../components/forms/OmButton';
import { PersonalAccidentContext } from './context/PersonalAccidentContext';
import { PAGE_INDICES, PROGRESS_BAR_STEPS } from './constants/routing';
import { removeLocalStorage } from '../../utils/personal-accident-utils/util';

const ApplicationWizardHeader = ({ callMeBack }) => {
    const { accidentActivePageIndex } = useContext(PersonalAccidentContext);
    const closeHander = () => {
        let check  = confirm('Are you sure you want to close this page?')
        if(check){
            if (typeof window !== 'undefined') {
                localStorage.clear();
            }
            navigate('/personal');
        }
    };

    if(accidentActivePageIndex === PAGE_INDICES.START){
        return <></>;
    }else{
        return (
            <OmApplicationWizardHeader
                helpNumber="0307 000 600"
                className="banner-image"
            >
                <div slot="wizard-title">
                    <p>
                        {Object.keys(PAGE_INDICES)
                            [accidentActivePageIndex]?.toString()
                            .replace('_', ' ')
                            .toUpperCase()}
                    </p>
                    <h5>
                        <strong>Personal Accident</strong>
                    </h5>
                </div>

                <span slot="contact-button">
                    <OmButton
                        type="secondary"
                        actionType="Call Me Back"
                        size="small"
                        text="Call me back"
                        callMeBackObject={callMeBack ? JSON.stringify(callMeBack) : ''}
                    />
                </span>
                <a slot="close-button" className='joureyCloseNameContainer'>
                    <span className='joureyCloseName' onClick={() => closeHander()}>Close</span>
                    <OmIcon
                        className="close-icon"
                        iconName="close"
                        onClick={() => closeHander()}
                    /> 
                </a>

                <div slot="progress-bar">
                    <Om1ColLayout className="header">
                        <OmApplicationProgressBar
                            applicationName="Personal Accident"
                            closable
                            hideDesktop={accidentActivePageIndex === PAGE_INDICES.SUCCESS}
                            // hideMobile={accidentActivePageIndex === PAGE_INDICES.SUCCESS}
                            className="banner-image"
                            steps={PROGRESS_BAR_STEPS}
                            index={
                                accidentActivePageIndex === PAGE_INDICES.BENEFICIARIES_SPLIT ? PAGE_INDICES.BENEFICIARIES 
                                    : accidentActivePageIndex === PAGE_INDICES.START ? PAGE_INDICES.PERSONAL_DETAILS : accidentActivePageIndex
                            }
                        >
                            <OmButton
                                actionType="url"
                                slot="close-application"
                                type="text"
                                size="small"
                                // url="/"
                                onClick={() => closeHander()}
                                text="CLOSE"
                            />
                        </OmApplicationProgressBar>
                    </Om1ColLayout>
                </div>
            </OmApplicationWizardHeader>
        );
    }
    
};

export default ApplicationWizardHeader;
