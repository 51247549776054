module.exports.COUNTRIES = [
    {fullcode: 'SouthAfrica', description: 'South Africa'},
    {fullcode: 'Afghanistan', description: 'Afghanistan'},
    {fullcode: 'AlandIslands', description: 'Aland Islands'},
    {fullcode: 'Albania', description: 'Albania'},
    {fullcode: 'Algeria', description: 'Algeria'},
    {fullcode: 'AmericanSamoa', description: 'American Samoa'},
    {fullcode: 'Andorra', description: 'Andorra'},
    {fullcode: 'Angola', description: 'Angola'},
    {fullcode: 'Anguilla', description: 'Anguilla'},
    {fullcode: 'Antarctica', description: 'Antarctica'},
    {fullcode: 'AntiguaandBarbuda', description: 'Antigua and Barbuda'},
    {fullcode: 'Argentina', description: 'Argentina'},
    {fullcode: 'Armenia', description: 'Armenia'},
    {fullcode: 'Aruba', description: 'Aruba'},
    {fullcode: 'Australia', description: 'Australia'},
    {fullcode: 'Austria', description: 'Austria'},
    {fullcode: 'Azerbaijan', description: 'Azerbaijan'},
    {fullcode: 'Bahamas', description: 'Bahamas'},
    {fullcode: 'Bahrain', description: 'Bahrain'},
    {fullcode: 'Bangladesh', description: 'Bangladesh'},
    {fullcode: 'Barbados', description: 'Barbados'},
    {fullcode: 'Belarus', description: 'Belarus'},
    {fullcode: 'Belgium', description: 'Belgium'},
    {fullcode: 'Belize', description: 'Belize'},
    {fullcode: 'Benin', description: 'Benin'},
    {fullcode: 'Bermuda', description: 'Bermuda'},
    {fullcode: 'Bhutan', description: 'Bhutan'},
    {fullcode: 'BoliviaPlurinationalStateof', description: 'Bolivia (Plurinational State of)'},
    {fullcode: 'BonaireSintEustatiusandSaba', description: 'Bonaire, Sint Eustatius and Saba'},
    {fullcode: 'BosniaandHerzegovina', description: 'Bosnia and Herzegovina'},
    {fullcode: 'Botswana', description: 'Botswana'},
    {fullcode: 'BouvetIsland', description: 'Bouvet Island'},
    {fullcode: 'Brazil', description: 'Brazil'},
    {fullcode: 'BritishIndianOceanTerritory', description: 'British Indian Ocean Territory'},
    {fullcode: 'BruneiDarussalam', description: 'Brunei Darussalam'},
    {fullcode: 'Bulgaria', description: 'Bulgaria'},
    {fullcode: 'BurkinaFaso', description: 'Burkina Faso'},
    {fullcode: 'Burundi', description: 'Burundi'},
    {fullcode: 'CaboVerde', description: 'Cabo Verde'},
    {fullcode: 'Cambodia', description: 'Cambodia'},
    {fullcode: 'Cameroon', description: 'Cameroon'},
    {fullcode: 'Canada', description: 'Canada'},
    {fullcode: 'CaymanIslands', description: 'Cayman Islands'},
    {fullcode: 'CentralAfricanRepublic', description: 'Central African Republic'},
    {fullcode: 'Chad', description: 'Chad'},
    {fullcode: 'Chile', description: 'Chile'},
    {fullcode: 'China', description: 'China'},
    {fullcode: 'ChristmasIsland', description: 'Christmas Island'},
    {fullcode: 'CocosKeelingIslands', description: 'Cocos (Keeling) Islands'},
    {fullcode: 'Colombia', description: 'Colombia'},
    {fullcode: 'Comoros', description: 'Comoros'},
    {fullcode: 'Congo', description: 'Congo'},
    {fullcode: 'CongoDemocraticRepublicOfCongo', description: 'Congo, Democratic Republic of Congo'},
    {fullcode: 'CookIslands', description: 'Cook Islands'},
    {fullcode: 'CostaRica', description: 'Costa Rica'},
    {fullcode: 'CôtedIvoire', description: "Côte d'Ivoire"},
    {fullcode: 'Croatia', description: 'Croatia'},
    {fullcode: 'Cuba', description: 'Cuba'},
    {fullcode: 'Curaçao', description: 'Curaçao'},
    {fullcode: 'Cyprus', description: 'Cyprus'},
    {fullcode: 'Czechia', description: 'Czechia'},
    {fullcode: 'Denmark', description: 'Denmark'},
    {fullcode: 'Djibouti', description: 'Djibouti'},
    {fullcode: 'Dominica', description: 'Dominica'},
    {fullcode: 'DominicanRepublic', description: 'Dominican Republic'},
    {fullcode: 'Ecuador', description: 'Ecuador'},
    {fullcode: 'Egypt', description: 'Egypt'},
    {fullcode: 'ElSalvador', description: 'El Salvador'},
    {fullcode: 'EquatorialGuinea', description: 'Equatorial Guinea'},
    {fullcode: 'Eritrea', description: 'Eritrea'},
    {fullcode: 'Estonia', description: 'Estonia'},
    {fullcode: 'Eswatini', description: 'Eswatini'},
    {fullcode: 'Ethiopia', description: 'Ethiopia'},
    {fullcode: 'FalklandIslandsMalvinas', description: 'Falkland Islands (Malvinas)'},
    {fullcode: 'FaroeIslands', description: 'Faroe Islands'},
    {fullcode: 'Fiji', description: 'Fiji'},
    {fullcode: 'Finland', description: 'Finland'},
    {fullcode: 'France', description: 'France'},
    {fullcode: 'FrenchGuiana', description: 'French Guiana'},
    {fullcode: 'FrenchPolynesia', description: 'French Polynesia'},
    {fullcode: 'FrenchSouthernTerritories', description: 'French Southern Territories'},
    {fullcode: 'Gabon', description: 'Gabon'},
    {fullcode: 'Gambia', description: 'Gambia'},
    {fullcode: 'Georgia', description: 'Georgia'},
    {fullcode: 'Germany', description: 'Germany'},
    {fullcode: 'Ghana', description: 'Ghana'},
    {fullcode: 'Gibraltar', description: 'Gibraltar'},
    {fullcode: 'Greece', description: 'Greece'},
    {fullcode: 'Greenland', description: 'Greenland'},
    {fullcode: 'Grenada', description: 'Grenada'},
    {fullcode: 'Guadeloupe', description: 'Guadeloupe'},
    {fullcode: 'Guam', description: 'Guam'},
    {fullcode: 'Guatemala', description: 'Guatemala'},
    {fullcode: 'Guernsey', description: 'Guernsey'},
    {fullcode: 'Guinea', description: 'Guinea'},
    {fullcode: 'GuineaBissau', description: 'Guinea-Bissau'},
    {fullcode: 'Guyana', description: 'Guyana'},
    {fullcode: 'Haiti', description: 'Haiti'},
    {fullcode: 'HeardIslandandMcDonaldIslands', description: 'Heard Island and McDonald Islands'},
    {fullcode: 'HolySee', description: 'Holy See'},
    {fullcode: 'Honduras', description: 'Honduras'},
    {fullcode: 'HongKong', description: 'Hong Kong'},
    {fullcode: 'Hungary', description: 'Hungary'},
    {fullcode: 'Iceland', description: 'Iceland'},
    {fullcode: 'India', description: 'India'},
    {fullcode: 'Indonesia', description: 'Indonesia'},
    {fullcode: 'Iran', description: 'Iran'},
    {fullcode: 'Iraq', description: 'Iraq'},
    {fullcode: 'Ireland', description: 'Ireland'},
    {fullcode: 'IsleofMan', description: 'Isle of Man'},
    {fullcode: 'Israel', description: 'Israel'},
    {fullcode: 'Italy', description: 'Italy'},
    {fullcode: 'Jamaica', description: 'Jamaica'},
    {fullcode: 'Japan', description: 'Japan'},
    {fullcode: 'Jersey', description: 'Jersey'},
    {fullcode: 'Jordan', description: 'Jordan'},
    {fullcode: 'Kazakhstan', description: 'Kazakhstan'},
    {fullcode: 'Kenya', description: 'Kenya'},
    {fullcode: 'Kiribati', description: 'Kiribati'},
    {fullcode: 'SouthKorea', description: 'South Korea'},
    {fullcode: 'NorthKorea', description: 'North Korea'},
    {fullcode: 'Kuwait', description: 'Kuwait'},
    {fullcode: 'Kyrgyzstan', description: 'Kyrgyzstan'},
    {fullcode: 'LaoPeoplesDemocraticRepublic', description: "Lao People's Democratic Republic"},
    {fullcode: 'Latvia', description: 'Latvia'},
    {fullcode: 'Lebanon', description: 'Lebanon'},
    {fullcode: 'Lesotho', description: 'Lesotho'},
    {fullcode: 'Liberia', description: 'Liberia'},
    {fullcode: 'Libya', description: 'Libya'},
    {fullcode: 'Liechtenstein', description: 'Liechtenstein'},
    {fullcode: 'Lithuania', description: 'Lithuania'},
    {fullcode: 'Luxembourg', description: 'Luxembourg'},
    {fullcode: 'Macao', description: 'Macao'},
    {fullcode: 'Madagascar', description: 'Madagascar'},
    {fullcode: 'Malawi', description: 'Malawi'},
    {fullcode: 'Malaysia', description: 'Malaysia'},
    {fullcode: 'Maldives', description: 'Maldives'},
    {fullcode: 'Mali', description: 'Mali'},
    {fullcode: 'Malta', description: 'Malta'},
    {fullcode: 'MarshallIslands', description: 'Marshall Islands'},
    {fullcode: 'Martinique', description: 'Martinique'},
    {fullcode: 'Mauritania', description: 'Mauritania'},
    {fullcode: 'Mauritius', description: 'Mauritius'},
    {fullcode: 'Mayotte', description: 'Mayotte'},
    {fullcode: 'Mexico', description: 'Mexico'},
    {fullcode: 'MicronesiaFederatedStatesof', description: 'Micronesia (Federated States of)'},
    {fullcode: 'Moldova', description: 'Moldova'},
    {fullcode: 'Monaco', description: 'Monaco'},
    {fullcode: 'Mongolia', description: 'Mongolia'},
    {fullcode: 'Montenegro', description: 'Montenegro'},
    {fullcode: 'Montserrat', description: 'Montserrat'},
    {fullcode: 'Morocco', description: 'Morocco'},
    {fullcode: 'Mozambique', description: 'Mozambique'},
    {fullcode: 'Myanmar', description: 'Myanmar'},
    {fullcode: 'Namibia', description: 'Namibia'},
    {fullcode: 'Nauru', description: 'Nauru'},
    {fullcode: 'Nepal', description: 'Nepal'},
    {fullcode: 'Netherlands', description: 'Netherlands'},
    {fullcode: 'NewCaledonia', description: 'New Caledonia'},
    {fullcode: 'NewZealand', description: 'New Zealand'},
    {fullcode: 'Nicaragua', description: 'Nicaragua'},
    {fullcode: 'Niger', description: 'Niger'},
    {fullcode: 'Nigeria', description: 'Nigeria'},
    {fullcode: 'Niue', description: 'Niue'},
    {fullcode: 'NorfolkIsland', description: 'Norfolk Island'},
    {fullcode: 'NorthMacedonia', description: 'North Macedonia'},
    {fullcode: 'NorthernMarianaIslands', description: 'Northern Mariana Islands'},
    {fullcode: 'Norway', description: 'Norway'},
    {fullcode: 'Oman', description: 'Oman'},
    {fullcode: 'Pakistan', description: 'Pakistan'},
    {fullcode: 'Palau', description: 'Palau'},
    {fullcode: 'PalestineStateof', description: 'Palestine, State of'},
    {fullcode: 'Panama', description: 'Panama'},
    {fullcode: 'PapuaNewGuinea', description: 'Papua New Guinea'},
    {fullcode: 'Paraguay', description: 'Paraguay'},
    {fullcode: 'Peru', description: 'Peru'},
    {fullcode: 'Philippines', description: 'Philippines'},
    {fullcode: 'Pitcairn', description: 'Pitcairn'},
    {fullcode: 'Poland', description: 'Poland'},
    {fullcode: 'Portugal', description: 'Portugal'},
    {fullcode: 'PuertoRico', description: 'Puerto Rico'},
    {fullcode: 'Qatar', description: 'Qatar'},
    {fullcode: 'Réunion', description: 'Réunion'},
    {fullcode: 'Romania', description: 'Romania'},
    {fullcode: 'RussianFederation', description: 'Russian Federation'},
    {fullcode: 'Rwanda', description: 'Rwanda'},
    {fullcode: 'SaintBarthélemy', description: 'Saint Barthélemy'},
    {fullcode: 'SaintKittsandNevis', description: 'Saint Kitts and Nevis'},
    {fullcode: 'SaintLucia', description: 'Saint Lucia'},
    {fullcode: 'SaintMartinFrenchpart', description: 'Saint Martin (French part)'},
    {fullcode: 'SaintPierreandMiquelon', description: 'Saint Pierre and Miquelon'},
    {fullcode: 'SaintVincentandtheGrenadines', description: 'Saint Vincent and the Grenadines'},
    {fullcode: 'Samoa', description: 'Samoa'},
    {fullcode: 'SanMarino', description: 'San Marino'},
    {fullcode: 'SaoTomeandPrincipe', description: 'Sao Tome and Principe'},
    {fullcode: 'SaudiArabia', description: 'Saudi Arabia'},
    {fullcode: 'Senegal', description: 'Senegal'},
    {fullcode: 'Serbia', description: 'Serbia'},
    {fullcode: 'Seychelles', description: 'Seychelles'},
    {fullcode: 'SierraLeone', description: 'Sierra Leone'},
    {fullcode: 'Singapore', description: 'Singapore'},
    {fullcode: 'SintMaartenDutchpart', description: 'Sint Maarten (Dutch part)'},
    {fullcode: 'Slovakia', description: 'Slovakia'},
    {fullcode: 'Slovenia', description: 'Slovenia'},
    {fullcode: 'SolomonIslands', description: 'Solomon Islands'},
    {fullcode: 'Somalia', description: 'Somalia'},
    {fullcode: 'SouthSudan', description: 'South Sudan'},
    {fullcode: 'Spain', description: 'Spain'},
    {fullcode: 'SriLanka', description: 'Sri Lanka'},
    {fullcode: 'Sudan', description: 'Sudan'},
    {fullcode: 'Suriname', description: 'Suriname'},
    {fullcode: 'SvalbardandJanMayen', description: 'Svalbard and Jan Mayen'},
    {fullcode: 'Sweden', description: 'Sweden'},
    {fullcode: 'Switzerland', description: 'Switzerland'},
    {fullcode: 'Syria', description: 'Syrian'},
    {fullcode: 'Taiwan', description: 'Taiwan'},
    {fullcode: 'Tajikistan', description: 'Tajikistan'},
    {fullcode: 'Tanzania', description: 'Tanzania'},
    {fullcode: 'Thailand', description: 'Thailand'},
    {fullcode: 'TimorLeste', description: 'Timor-Leste'},
    {fullcode: 'Togo', description: 'Togo'},
    {fullcode: 'Tokelau', description: 'Tokelau'},
    {fullcode: 'Tonga', description: 'Tonga'},
    {fullcode: 'TrinidadandTobago', description: 'Trinidad and Tobago'},
    {fullcode: 'Tunisia', description: 'Tunisia'},
    {fullcode: 'Turkey', description: 'Turkey'},
    {fullcode: 'Turkmenistan', description: 'Turkmenistan'},
    {fullcode: 'TurksandCaicosIslands', description: 'Turks and Caicos Islands'},
    {fullcode: 'Tuvalu', description: 'Tuvalu'},
    {fullcode: 'Uganda', description: 'Uganda'},
    {fullcode: 'Ukraine', description: 'Ukraine'},
    {fullcode: 'UnitedArabEmirates', description: 'United Arab Emirates'},
    {fullcode: 'UnitedKingdom', description: 'United Kingdom'},
    {fullcode: 'UnitedStatesofAmerica', description: 'United States of America'},
    {fullcode: 'UnitedStatesMinorOutlyingIslands', description: 'United States Minor Outlying Islands'},
    {fullcode: 'Uruguay', description: 'Uruguay'},
    {fullcode: 'Uzbekistan', description: 'Uzbekistan'},
    {fullcode: 'Vanuatu', description: 'Vanuatu'},
    {fullcode: 'Venezuela', description: 'Venezuela'},
    {fullcode: 'VietNam', description: 'Viet Nam'},
    {fullcode: 'VirginIslandsBritish', description: 'Virgin Islands (British)'},
    {fullcode: 'VirginIslandsUS', description: 'Virgin Islands (U.S.)'},
    {fullcode: 'WallisandFutuna', description: 'Wallis and Futuna'},
    {fullcode: 'WesternSahara', description: 'Western Sahara'},
    {fullcode: 'Yemen', description: 'Yemen'},
    {fullcode: 'Zambia', description: 'Zambia'},
    {fullcode: 'Zimbabwe', description: 'Zimbabwe'},
];