import React, { useContext }  from 'react';
import {OmFormInputFieldWrapper} from '@om/component-library-react';
import { PersonalAccidentContext } from '../../context/PersonalAccidentContext';
import { DEFAULT_VALIDATION_DELAY } from '../../../../configs/personal-accident-configs/config';
import { addWeeksToDate, calculateSumAssuredPremium, formatOccupationClass, isDateInPast, isInputValid } from '../../../../utils/personal-accident-utils/util';
import { OmdsDatePickerField } from '@mom-omds/react-design-system-components/dist/generated/components';

const Policy = () => {

    const { accidentData, updateAccidentData } = useContext(PersonalAccidentContext);

    const handleChange = (e) => {
        const value = e.detail;
        const input = e.target.name;

        if(input === 'sum_assured'){
            if(parseFloat(value) < 1000 || parseFloat(value) > 40000){
                updateAccidentData({ [input]: null }, 'schemeDetails');
                return;
            }
        }else if(input === 'commencement_date'){
            const dateIn2weeks = addWeeksToDate(new Date(), 2);
            if(new Date(value) > dateIn2weeks){
                updateAccidentData({ [input]: null }, 'schemeDetails');
                return;
            }else if(isDateInPast(value)){
                updateAccidentData({ [input]: null }, 'schemeDetails');
                return;
            }
        }
        updateAccidentData({ [input]: value }, 'schemeDetails');
    }

    return (
        <div className="mvest-scheme-details-page" id='pa_policy_page'>
            <div className="mvest-scheme-details-page-wrapper">
                <h5 className="heading medium" id="heading-anchor">You’re doing well.  Now, lets fill in the policy details</h5>
                <p>This is an annual product and will expire a year from the commencement date.</p>


                <OmFormInputFieldWrapper
                    label="Sum assured"
                    id="sum_assured"
                    name="sum_assured"
                    labelAlwaysShow={true}
                    isSensitive
                    required
                    formId="schemeDetailsForm"
                    size="full"
                    align="left"
                    type="text"
                    placeholder="minimum of GHc1,000 and maximum of GHc40,000"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={accidentData?.schemeDetails?.sum_assured}
                    state={isInputValid(accidentData?.schemeDetails?.sum_assured)}
                    errorMessage={isInputValid(accidentData?.schemeDetails?.sum_assured) === 'error' ? 'Amount minimum is 1,000 and cannot exceed 40,000' : undefined }
                    onPersist={(e) => handleChange(e)}
                />
            
                <OmdsDatePickerField
                    id='commencement_date'
                    label="Commencement Date"
                    name="commencement_date"
                    value={accidentData?.schemeDetails?.commencement_date}
                    hasError={isInputValid(accidentData?.schemeDetails?.commencement_date) === 'error'}
                    onPersist={(e) => handleChange(e)}
                    placeholder="e.g. 2024-05-05">
                    {isInputValid(accidentData?.schemeDetails?.commencement_date) === 'error' ? (<span slot="hint-text">{'Commencement date cannot be a past date and not more than 2weeks from today'}</span>) : undefined}
                </OmdsDatePickerField>

                {accidentData?.schemeDetails?.sum_assured ? <p>Your payable premium is: <strong>GHc{calculateSumAssuredPremium(accidentData?.schemeDetails?.sum_assured, formatOccupationClass(accidentData?.schemeDetails?.occupation_class))}</strong> </p>: ''}
            </div>
        </div>
    )
}
export default Policy;