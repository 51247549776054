export const PAGE_INDICES = {
    START: 0,
    PERSONAL_DETAILS: 1,
    HEALTH_INFORMATION: 2,
    POLICY_DETAILS: 3,
    BENEFICIARIES: 4,
    BENEFICIARIES_SPLIT: 5,
    REVIEW_APPLICATION: 6,
    SUCCESS: 7,
};

export const PROGRESS_BAR_STEPS = [
    'Get Started',
    'About You',
    'Health Information',
    'Cover Details',
    'Beneficiary',
    'Confirmation',
].join('|');
